/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb 
        - Preloader   
        - Scroll top 
        - Dividers Css 
        - Google Map Css 
    02. Section Spacing Css
    03. Custom Animation Css
    04. container Css 
    05. Header Css 
        - language
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Mobile Menu Overlay Css
        - offset search
        - search overlay
    06. Footer Css
    07. Sidebar Css
    08. Button Css 
    09. Accordions Css 
    10. Call To Action Css  
    11. Countdown Css 
    12. Counters Css 
    13. Box Icon Css
    14. Box Images Css
    15. Box Large Images Css
    16. Brand Logo Css  
    17. Listing Css
    18. Gradation Process Css     
    19. Instagram Css     
    20. Message Box Css 
    21. Video Popup Css
    22. Pricing table Css
    23. progress Bar Css 
    24. Circle Progress Css
    25. Row Separators Css
    26. Social Networks Css 
    27. Listing Css
    29. Team Member Css 
    30. Timeline Css
    31. Testimonial slider Css 
    32. Project Css 
    33. Contact Us Css 
    34. Blog Pages Css
    35. Infotechno Css  
    36. Processing Hero Css
    37. Processing Hero Css
    38. Resolutions home Css
    39. Cybersecurity home Css  
    40. About Us pages Css
    41. Single Smart Vision Css
    42. Redraw Css
    43. Preview Page Css 


/*=====  End of CSS Index  ======*/
/*=============================================
=             01. Default Css                 =
=============================================*/
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.74;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "CerebriSans";
  color: #696969;
  position: relative;
  background-color: #ffffff;
}

body.no-overflow {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #333333;
  font-family: "CerebriSans";
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.41;
}

h1 {
  font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h5 {
    font-size: 20px;
  }
}

h6 {
  font-size: 18px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  color: #086AD8;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.form-messege.success,
.form-messege-2.success {
  color: green;
}

.form-messege.error,
.form-messege-2.error {
  color: red;
}

.mark, mark {
  padding: 0 0;
  background-color: transparent;
}

.font-weight--bold {
  font-weight: 800;
}

.font-weight--reguler {
  font-weight: 500;
}

.font-weight--normal {
  font-weight: 400;
}

.font-weight--light {
  font-weight: 300;
}

/*--
    Default Class
*/
.text-color-primary {
  color: #086AD8;
}

.text-color-secondary {
  color: #d2a98e;
}

.text-black {
  color: #333333 !important;
}

.bg-white {
  background: #ffffff;
}

.bg-black {
  background: #333;
}

.bg-gray {
  background: #F8F8F8;
}

.bg-gray-2 {
  background: #F6FAFE;
}

.bg-gray-3 {
  background: #f6f2ed;
}

.bg-theme-default {
  background: #086AD8;
}

.theme-bg-secondary {
  background: #d2a98e;
}

.bg-theme-three {
  background: #002FA6;
}

.sub-heading {
  color: #6d70a6;
}

.black-bg {
  background: #000000;
}

.border-radus-5 {
  border-radius: 5px;
}

.text-green {
  color: #d2a98e;
}

.bg-gradient {
  background: -webkit-linear-gradient(top, #FFF 0, #F5F5F5 100%);
}

/*-- Tab Content & Pane Fix --*/
select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px;
  background-color: #f8f8f8;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.fixed-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*------- Defauld Class --------*/
.text-black {
  color: #333;
}

.box-shadow-top {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.border {
  border: 1px solid #ededed !important;
}

.border-top {
  border-top: 1px solid #ededed !important;
}

.border-right {
  border-right: 1px solid #ededed !important;
}

.border-bottom {
  border-bottom: 1px solid #ededed !important;
}

.border-left {
  border-left: 1px solid #ededed !important;
}

.border-top-dash {
  border-top: 1px dashed #ddd !important;
}

.border-bottom-dash {
  border-bottom: 1px dashed #ddd !important;
}

.border-top-thick {
  border-top: 2px solid #ededed !important;
}

.border-bottom-thick {
  border-bottom: 2px solid #ededed !important;
}

.border-top-drak {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom-drak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.section-title mark {
  color: #38cb89;
  padding: .0em;
  background-color: transparent;
}

.section-sub-title {
  color: #ababab;
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.43;
  margin-top: -5px;
}

.section-under-heading {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.40;
  color: #333333;
}

.section-under-heading a {
  color: #086AD8;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.section-under-heading a::before {
  content: '';
  width: 0;
  height: 1px;
  bottom: -1px;
  position: absolute;
  left: auto;
  right: 0;
  z-index: 1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.section-under-heading a:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}

.img-width {
  width: 100%;
}

.title-dec-text {
  text-align: center;
  max-width: 760px;
  margin: auto;
}

::selection {
  color: #ffffff;
  background-color: #086AD8;
}

form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

form input:focus::-webkit-input-placeholder {
  color: transparent;
}

form input:focus:-moz-placeholder {
  color: transparent;
}

form input:focus::-moz-placeholder {
  color: transparent;
}

form input:focus:-ms-input-placeholder {
  color: transparent;
}

form input::placeholder, form textarea::placeholder {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: #086AD8;
  border-color: #086AD8;
}

input[type="checkbox"] {
  position: relative;
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin: 0 10px 0 3px;
  cursor: pointer;
}

/* swiper default styles */
.swiper-pagination {
  position: static;
  display: block;
  line-height: 1;
}

.swiper-pagination--vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  bottom: auto;
  left: auto;
}

.swiper-pagination--vertical .swiper-pagination-bullet {
  display: block;
  margin-bottom: 10px;
  line-height: 1;
  margin-right: 0;
}

.swiper-pagination--vertical .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 0;
  border-radius: 0;
  background: transparent;
  opacity: 1;
  position: relative;
  outline: none;
  margin-right: 20px;
}

.swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.swiper-pagination .swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background: #d8d8d8;
  z-index: 1;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-pagination .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #086AD8;
}

.swiper-pagination .swiper-pagination-bullet-active:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #086AD8;
}

.site-wrapper-reveal {
  position: relative;
  z-index: 2;
  background: #ffffff;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet {
  margin: 8px;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #fff;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet-active::before {
  opacity: 1;
  background: #fff;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 9px #ededed;
}

.swiper-pagination.swiper-pagination-black .swiper-pagination-bullet {
  margin: 8px;
}

.swiper-pagination.swiper-pagination-black .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #333;
}

.swiper-pagination.swiper-pagination-black .swiper-pagination-bullet-active::before {
  opacity: 1;
  background: #333;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 9px #8b7b7b;
}

.swiper-nav-button {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s, opacity 0.1s linear 2s;
  background-image: none;
  text-align: center;
  user-select: none;
  outline: none;
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: #6d70a6;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  opacity: 0;
  visibility: hidden;
  transform: translate(0, -50%);
  margin: 0;
  top: 50%;
}

.swiper-nav-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  color: #fff;
  background: #fff;
}

.swiper-nav-button:hover {
  color: #fff;
}

.swiper-nav-button:hover::before {
  color: #fff;
  background: #086AD8;
}

.swiper-container {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-container:hover .swiper-nav-button {
  opacity: 1;
  visibility: visible;
}

.swiper-nav-button i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-nav-button i::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: '\f104';
}

.swiper-nav-prev i::before {
  content: '\f104';
}

.swiper-button-next i::before {
  content: '\f105';
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.single-element-wrap ul li {
  margin-bottom: 15px;
}

.single-element-wrap ul li:last-child {
  margin-bottom: 0;
}

/*===================================
    - Breadvrumb  
=================================*/
.breadcrumb-area {
  padding-top: 114px;
  padding-bottom: 114px;
  background-color: #f6f2ed;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
  padding-top: 136px;
  padding-bottom: 131px;
  background-size: cover;
  z-index: 3;
  position: relative;
  background-image: url(../images/bg/title-bar-01-bg.jpg);
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.breadcrumb-list {
  margin-top: 20px;
}

.breadcrumb-list li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.58;
  text-transform: uppercase;
}

.breadcrumb-list li a {
  position: relative;
}

.breadcrumb-list li a::after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.breadcrumb-list li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}

.breadcrumb-list li.active {
  color: #6D70A6;
}

.page-pagination li {
  display: inline-block;
}

.page-pagination li a {
  font-weight: 500;
  padding: 0 10px;
  display: block;
  text-align: center;
  line-height: 41px;
  min-width: 41px;
  height: 41px;
  text-transform: uppercase;
  color: #ababab;
  letter-spacing: 2px;
  border-radius: 5px;
}

.page-pagination li a.current {
  background: #f6f5f9;
  color: #086AD8;
}

.page-pagination li a:hover {
  color: #086AD8;
}

/*=============================================
=                - Preloader                =
=============================================*/
.preloader-active {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}

.preloader-active .preloader-area-wrap {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
}

.preloader-active .preloader-area-wrap .spinner div {
  background-color: #086AD8;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.preloader-active .preloader-area-wrap .spinner div.bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.preloader-active .preloader-area-wrap .spinner div.bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.preloader-area-wrap {
  display: none;
}

.open_tm_preloader {
  position: fixed;
  background-color: transparent;
  z-index: 9999;
  height: 100%;
  width: 100%;
  -webkit-transition: .2s all ease;
  -o-transition: .2s all ease;
  transition: .2s all ease;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.open_tm_preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.open_tm_preloader.loaded:before, .open_tm_preloader.loaded:after {
  width: 0%;
}

.open_tm_preloader:before, .open_tm_preloader:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}

.open_tm_preloader:before {
  top: 0;
  left: 0;
}

.open_tm_preloader:after {
  bottom: 0;
  right: 0;
}

/*=============================================
   - Scroll top         
=============================================*/
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #086AD8;
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}

.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}

.scroll-top:hover {
  background-position: right center;
}

.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

/*=============================================
   - Dividers Css        
=============================================*/
.separator-wrap {
  text-align: center;
  display: inline-block;
  margin-top: 40px;
}

.separator-wrap .dot {
  border-radius: 50%;
  box-shadow: 0 0 12px #def0ff;
  background: #000;
  background-color: #086AD8;
  animation: separator-bounce 1.4s ease-in-out 0s infinite both;
  width: 14px;
  height: 14px;
}

.separator-wrap .dot.second-circle {
  margin-top: 18px;
  animation-delay: -.16s;
}

.separator-wrap .dot.third-circle {
  margin-top: 14px;
  animation-delay: -.32s;
}

@-webkit-keyframes separator-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes separator-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*=================================
=          - Google Map Css       =                
===================================*/
#googleMap-1, #googleMap-2, #googleMap-3 {
  height: 400px;
}

/*=====  End of Default CSS  ======*/
/*============================================
=           02. Section Spacing Css          =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--pt_150 {
  padding-top: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space--pt_150 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_150 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_150 {
    padding-top: 60px;
  }
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_80 {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_80 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_80 {
    padding-top: 40px;
  }
}

.section-space--pb_80 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_80 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_80 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--pt_30 {
  padding-top: 30px;
}

.section-space--pb_30 {
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-space--mb_40 {
    margin-bottom: 30px;
  }
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .small-mt__0 {
    margin-top: 0px;
  }
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mt__50 {
    margin-top: 50px;
  }
  .small-mt__60 {
    margin-top: 60px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
  .small-mb__50 {
    margin-bottom: 50px;
  }
  .small-mb__60 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__0 {
    margin-top: 0px;
  }
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mt__50 {
    margin-top: 50px;
  }
  .tablet-mt__60 {
    margin-top: 60px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
  .tablet-mb__50 {
    margin-bottom: 50px;
  }
  .tablet-mb__60 {
    margin-bottom: 60px;
  }
}

/*=====  End of spacing  ======*/
/*===========================================
=        03. Custom Animation Css
===========================================*/
@-webkit-keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.move-up {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.move-up.animate {
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-animation: moveVertical .65s ease forwards;
  animation: moveVertical .65s ease forwards;
}

.move-up-x {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.move-up-x.animate {
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-animation: moveHorizontal .65s ease forwards;
  animation: moveHorizontal .65s ease forwards;
}

/* ------------------------
    Custom Animation 01 
----------------------------*/
@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}

/* --------------------------------------
    Custom Hide Animation
---------------------------------------*/
@-webkit-keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(60px);
    opacity: 0;
  }
}

@keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
  }
}

/* --------------------------------------
    Custom Slide Show Animation
---------------------------------------*/
@-webkit-keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* --------------------------------------
    Custom Slide Out To Right
---------------------------------------*/
@-webkit-keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(40px);
    opacity: 0;
  }
}

@keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0;
  }
}

/* --------------------------------------
    Custom Slide Out To Left
---------------------------------------*/
@-webkit-keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ------------------------
    Brook Hover Effect 
---------------------------*/
.brook-transition {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.bk-hover a {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  color: #999;
}

.bk-hover a::after {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.bk-hover a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

/* ------------------------
    Separator Bounce 
---------------------------*/
@-webkit-keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*=============================================
=            04. container Css            =
=============================================*/
@media (min-width: 1600px) {
  .container-fluid--cp-150 {
    padding: 0 150px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-150 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-150 {
    padding: 0 50px;
  }
}

@media (min-width: 1700px) {
  .container-fluid--cp-140 {
    padding: 0 140px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1663px) {
  .container-fluid--cp-140 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-140 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-140 {
    padding: 0 30px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-120 {
    padding: 0 120px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-120 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-120 {
    padding: 0 50px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-80 {
    padding: 0 80px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-80 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}

.container-fluid--cp-60 {
  padding: 0 60px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-60 {
    padding: 0 15px;
  }
}

.container-fluid--cp-30 {
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-30 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .page-content-double-sidebar .container {
    max-width: 1600px;
  }
}

@media (max-width: 1919px) {
  .container-fluid--cp-60 {
    padding: 0 30px;
  }
  .container-fluid--cp-80 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 767px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 575px) {
  .container-fluid--cp-80 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container_fl {
  padding-left: 15px;
  padding-right: 15px;
}

.col-06__left {
  width: 600px;
  max-width: 100%;
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__left {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.col-06__right {
  width: 600px;
  max-width: 100%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__right {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.banner-image__content {
  width: 570px;
  max-width: 100%;
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--35 > [class*="col"],
.row--35 > [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--30 > [class*="col"],
.row--30 > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--10 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--10 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .row--10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

.row--10 > [class*="col"],
.row--10 > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/*=====  End of container  ======*/
/*=============================================
=              05. Header Css            =
=============================================*/
.header-area {
  position: relative;
  z-index: 9;
}

.header-area .is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li > a {
  color: #444;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li > a > span:after {
  background-color: #444;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li.active > a {
  color: #000000;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li:hover > a:after, .header-area .is-sticky .navigation-menu--text_white > ul > li.active > a:after {
  color: #000000;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li:hover > a > span, .header-area .is-sticky .navigation-menu--text_white > ul > li.active > a > span {
  color: #000000;
}

.header-area .is-sticky .header-search-form .search-form-top .style-02 {
  background-color: transparent;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.header-area .is-sticky .header-search-form .search-form-top.style-03 {
  background-color: transparent;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.header-area .is-sticky .header-search-form .search-form-top.style-03 input::placeholder, .header-area .is-sticky .header-search-form .search-form-top.style-03 textarea::placeholder {
  color: #333;
}

.header-area .is-sticky .header-search-form .search-form-top .search-field {
  color: #000;
}

.header-area .is-sticky .header-search-form .search-form-top .search-submit {
  color: #333;
}

.header-area .is-sticky .header__logo .dark-logo {
  display: inherit;
}

.header-area .is-sticky .header__logo .light-logo {
  display: none;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li > a {
  color: #333;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li:hover > a:after, .header-area .is-sticky .menu-style-four .navigation-menu > ul > li.active > a:after {
  color: #333;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li:hover > a > span, .header-area .is-sticky .menu-style-four .navigation-menu > ul > li.active > a > span {
  color: #333;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li:hover > a:before, .header-area .is-sticky .menu-style-four .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

.header-area .is-sticky .header-search-form-two.white-icon a {
  color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-area.is-sticky.only-mobile-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
    z-index: 999;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-area.is-sticky.only-mobile-sticky .header-bottom-wrap.is-sticky {
    display: none !important;
  }
}

.header-area--absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-area--absolute {
    position: static;
  }
}

.header-area--absolute__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.header-area--default {
  border-color: #E4E8F6;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.header {
  display: flex;
  align-items: center;
  /* logo */
}

.header__logo {
  flex-basis: 20%;
  padding: 14px 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.header__logo.top-logo {
  padding: 25px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header__logo {
    flex-basis: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo {
    flex-basis: 50%;
  }
  .header__logo.top-logo {
    padding: 15px 0;
  }
}

.header__logo > a img {
  width: 160px;
}

.header__logo .dark-logo {
  display: none;
}

.header__logo .light-logo {
  display: inherit;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__logo .dark-logo {
    display: inherit;
  }
  .header__logo .light-logo {
    display: none;
  }
}

.header.default-menu-style .header__logo {
  flex-basis: 23.5%;
  justify-content: center;
  padding: 14px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header.default-menu-style .header__logo {
    flex-basis: 13%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header.default-menu-style .header__logo {
    flex-basis: 40%;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .header.default-menu-style .header__logo {
    flex-basis: 50%;
    justify-content: flex-start;
  }
}

.header.default-menu-style .header-midle-box {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.header.default-menu-style .header-right-box {
  flex-shrink: 0;
  flex-basis: 23.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 23%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 50%;
  }
}

.header.default-menu-style .header-right-box .header-right-inner {
  display: flex;
  margin: 0 -12px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .header.default-menu-style .header-right-box .header-right-inner {
    margin: auto;
  }
}

/*======================
    - language 
=======================*/
.language-menu {
  padding-right: 25px;
}

.language-menu > ul > li {
  position: relative;
  display: inline-block;
  line-height: 1;
  padding: 0 10px;
}

.language-menu > ul > li > a {
  padding: 0 10px;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
}

.language-menu > ul > li > a span {
  margin-left: 10px;
}

.language-menu > ul > li > a::after {
  content: '\f078';
  font-size: 8px;
  border: 0;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.language-menu > ul > li ul {
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 102;
  box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
  transform: translateY(30px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.language-menu > ul > li ul li a {
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  display: block;
  text-decoration: none;
  padding: 10px;
  border: 0;
  background: #fff;
  color: #222;
  line-height: 1;
  text-transform: uppercase;
}

.language-menu > ul > li ul li a span {
  margin-left: 10px;
}

.language-menu > ul > li ul li a img {
  width: 24px;
  height: auto;
}

.language-menu > ul > li ul li a:hover {
  background: #EEEEEE;
}

.language-menu > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.header-right {
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.header-right-inner {
  display: flex;
  margin: 0 -12px;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 0 12px;
}

@media only screen and (max-width: 767px) {
  .header-right-inner {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
    transform: translateY(30px);
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .header-right-inner.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    z-index: 999;
  }
}

@media only screen and (max-width: 767px) {
  .header-right-box .header-right-inner {
    flex-direction: row;
  }
}

@media only screen and (max-width: 575px) {
  .header-right-box .header-right-inner {
    flex-direction: column;
  }
}

.header-top-info-slider-werap {
  overflow: hidden;
  max-width: 770px;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-info-slider-werap {
    max-width: 540px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-info-slider-werap {
    max-width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .header-top-info-slider-werap {
    max-width: 100%;
  }
}

.header-top-info-slider-werap {
  padding: 0 12px;
}

@media only screen and (max-width: 767px) {
  .header-top-info-slider-werap {
    padding: 0 0px;
  }
}

.header-top-info-inner .info-item {
  display: flex;
  align-items: center;
  border-left: 1px solid #ddd;
  padding-left: 19px;
}

.header-top-info-inner .info-item .info-icon {
  width: 41px;
  flex-shrink: 0;
  font-size: 30px;
  line-height: 1;
  color: #d2a98e;
}

.header-top-info-inner .info-item .info-content {
  flex-grow: 1;
}

.header-top-info-inner .info-item .info-content .info-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.18;
  margin-bottom: 6px;
}

.header-top-info-inner .info-item .info-content .info-sub-title {
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .header-top-info-inner .info-item {
    padding-left: 0px;
    margin-top: 20px;
  }
}

.header-top-info-inner.default-color .info-item .info-icon {
  color: #086AD8;
}

.top-info-slider__container {
  cursor: w-resize;
}

.top-info-slider__container .swiper-slide-active .info-item {
  border-left: none;
}

.header-social-networks.style-icons a {
  display: inline-block;
  padding: 0 10px;
  font-size: 18px;
  margin-top: 10px;
}

.header-bottom-inner {
  display: flex;
  align-items: center;
}

.header-bottom-left-wrap {
  flex-grow: 1;
}

.header-search-form {
  flex-shrink: 0;
  align-self: stretch;
}

.header-search-form .search-form-top {
  position: relative;
  background-color: rgba(0, 47, 166, 0.3);
}

.header-search-form .search-form-top .search-field {
  width: 270px;
  border-radius: 0;
  border: 0 !important;
  background: 0 0 !important;
  min-height: 60px;
  padding-right: 50px;
  padding-left: 10px;
  color: #fff;
}

.header-search-form .search-form-top input::placeholder, .header-search-form .search-form-top textarea::placeholder {
  color: #333;
}

.header-search-form .search-form-top .search-submit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 18px;
  background: 0 0;
  width: 48px;
  height: 48px;
  line-height: 50px;
  padding: 0;
  border: 0;
  color: #ffffff;
}

.header-search-form .search-form-top.style-02 {
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}

.header-search-form .search-form-top.style-02 .search-field {
  width: 232px;
  border-radius: 0;
  background: #fff !important;
  border: 0 !important;
  min-height: 60px;
  color: #333;
}

.header-search-form .search-form-top.style-02 .search-submit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 18px;
  background: 0 0;
  width: 48px;
  height: 48px;
  line-height: 50px;
  padding: 0;
  border: 0;
  color: #000000;
}

.header-search-form .search-form-top.style-03 input::placeholder {
  color: #ddd;
}

.header-search-form.default-search {
  height: 100%;
}

.header-search-form.default-search .search-form-top {
  background: transparent;
  border-left: 1px solid #eee;
  margin-right: -15px;
}

@media only screen and (max-width: 575px) {
  .header-search-form.default-search .search-form-top {
    margin-top: 15px;
    background: #eee;
  }
}

.header-search-form.default-search .search-form-top .search-field {
  max-width: 225px;
  color: #333;
  min-height: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header-search-form.default-search .search-form-top .search-field {
    max-width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .header-search-form.default-search .search-form-top .search-field {
    min-height: 40px;
  }
}

.header-search-form.default-search .search-form-top .search-submit {
  color: #000000;
}

.header-search-form-two {
  padding-left: 24px;
  display: block;
}

.header-search-form-two a {
  line-height: 0;
  font-size: 18px;
}

.header-search-form-two a:hover {
  transform: scale(1.15, 1.15);
}

.header-search-form-two.white-icon a {
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-search-form-two.white-icon a {
    color: #333;
  }
}

.top-bar-left {
  flex-grow: 1;
}

.top-bar-left .top-bar-text {
  font-size: 14px;
  line-height: 1.78;
}

.top-bar-left .top-bar-text .font-medium {
  font-weight: 500;
}

.top-bar-wrap {
  display: flex;
  align-items: center;
  height: 50px;
}

.top-bar-wrap .top-bar-right .top-bar-info .info-item {
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}

.top-bar-wrap .top-bar-right .top-bar-info .info-item:first-child {
  margin-left: 0;
}

.top-bar-wrap .top-bar-right .top-bar-info .info-item .info-icon {
  margin-right: 5px;
  font-size: 14px;
}

.top-message {
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.78;
}

.top-message a {
  color: #002FA6;
  font-weight: 500;
}

.top-message a:hover {
  color: #d2a98e;
}

/*============================== 
    - Navigation Menu Css
===============================*/
.navigation-menu > ul {
  display: flex;
}

.navigation-menu > ul > li {
  margin: 0 24px;
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .navigation-menu > ul > li {
    margin: 0 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .navigation-menu > ul > li {
    margin: 0 15px;
  }
}

.navigation-menu > ul > li > a {
  display: block;
  color: #000000;
  padding: 21px 2px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.18;
  transition: all 0.0s ease-in-out;
}

.navigation-menu > ul > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #086AD8;
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li > a span {
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li.has-children > a {
  position: relative;
}

.navigation-menu > ul > li.has-children > a:after {
  position: static;
  margin-left: 5px;
  font-family: "Font Awesome 5 Pro";
  content: '\f107';
  font-size: 14px;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li.has-children--multilevel-submenu {
  position: relative;
}

.navigation-menu > ul > li.has-children:hover .megamenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li.has-children:hover .megamenu--home-variation__item {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navigation-menu > ul > li.has-children:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li:hover > a:after, .navigation-menu > ul > li.active > a:after {
  color: #086AD8;
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li:hover > a span, .navigation-menu > ul > li.active > a span {
  color: #086AD8;
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li:hover > a:before, .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

.navigation-menu.primary--menu > ul > li > a {
  padding: 31px 2px;
}

.navigation-menu--text_white > ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}

.navigation-menu--text_white > ul > li > a:before {
  background-color: #ffffff;
}

.navigation-menu--text_white > ul > li.active > a {
  color: #ffffff;
}

.navigation-menu--text_white > ul > li:hover > a:after, .navigation-menu--text_white > ul > li.active > a:after {
  color: #ffffff;
}

.navigation-menu--text_white > ul > li:hover > a > span, .navigation-menu--text_white > ul > li.active > a > span {
  color: #ffffff;
}

.header-bottom-left-wrap .navigation-menu > ul > li:first-child {
  margin: 0 24px 0 0;
}

/*===================================
    - Multilevel Submenu Css
====================================*/
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #086AD8;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  min-width: 240px;
  padding: 20px 0;
  z-index: 9;
}

.submenu > li {
  position: relative;
}

.submenu > li > a {
  display: block;
  padding: 5px 30px;
  color: #ababab;
  font-weight: 400;
  transition: 0s;
}

.submenu > li > a > span {
  position: relative;
}

.submenu > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #086AD8;
  transition: 0.3s;
}

.submenu > li > a:hover {
  color: #086AD8;
}

.submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.submenu > li:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  z-index: 9;
}

.submenu > li.active > a {
  color: #086AD8;
}

.submenu > li.has-children > a {
  position: relative;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submenu > li.has-children > a:after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  content: '\f105';
  font-size: 14px;
  vertical-align: middle;
  color: #ababab;
}

.submenu > li.has-children > a:hover:after {
  color: #086AD8;
}

.submenu > li.has-children.active > a {
  color: #086AD8;
}

.submenu .submenu {
  top: 0px;
  left: 100%;
  right: auto;
}

.submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.menu-style-three .navigation-menu > ul > li, .menu-style-four .navigation-menu > ul > li {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0;
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .menu-style-three .navigation-menu > ul > li, .menu-style-four .navigation-menu > ul > li {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.menu-style-three .navigation-menu > ul > li:hover > a:before, .menu-style-three .navigation-menu > ul > li.active > a:before, .menu-style-four .navigation-menu > ul > li:hover > a:before, .menu-style-four .navigation-menu > ul > li.active > a:before {
  width: 0%;
  left: 0;
}

.menu-style-three .submenu.left, .menu-style-four .submenu.left {
  left: -100px;
}

.menu-style-three .submenu.left .submenu.left, .menu-style-four .submenu.left .submenu.left {
  right: 100%;
  left: auto;
}

.menu-style-four .navigation-menu > ul > li > a {
  color: #ffffff;
}

.menu-style-four .navigation-menu > ul > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #d2a98e;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.menu-style-four .navigation-menu > ul > li:hover > a:after, .menu-style-four .navigation-menu > ul > li.active > a:after {
  color: #ffffff;
}

.menu-style-four .navigation-menu > ul > li:hover > a > span, .menu-style-four .navigation-menu > ul > li.active > a > span {
  color: #ffffff;
}

.menu-style-four .navigation-menu > ul > li:hover > a:before, .menu-style-four .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

/*=========================================
    - Mega Menu Css
===========================================*/
.megamenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #086AD8;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.megamenu--mega {
  min-width: 980px;
  width: 100%;
  padding: 35px 20px 30px;
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    min-width: 700px;
  }
}

.megamenu--mega > li {
  flex-basis: 22%;
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu--mega > li .page-list-title {
  font-size: 14px;
  margin-bottom: 20px;
  color: #333333;
}

.megamenu--mega > li > ul > li > a {
  padding: 10px 0;
  color: #ababab;
  line-height: 1.2;
  transition: 0.1s;
}

.megamenu--mega > li > ul > li > a:hover {
  color: #086AD8;
}

.megamenu--mega > li > ul > li > a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.megamenu--mega > li > ul > li > a > span {
  position: relative;
}

.megamenu--mega > li > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #086AD8;
  transition: 0.3s;
}

.megamenu--mega > li > ul > li.active > a {
  color: #086AD8;
}

.container-fluid .megamenu--mega {
  padding: 35px 220px 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid .megamenu--mega {
    min-width: 700px;
  }
}

/* Mobile Mavigation icon */
.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
}

.mobile-navigation-icon:hover i {
  background-color: #086AD8;
}

.mobile-navigation-icon:hover i:before {
  width: 80%;
  background-color: #086AD8;
}

.mobile-navigation-icon:hover i:after {
  background-color: #086AD8;
  width: 60%;
}

.mobile-navigation-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-navigation-icon.white-md-icon i {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:before {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:after {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon:hover i {
    background-color: #086AD8;
  }
  .mobile-navigation-icon.white-md-icon:hover i:before {
    width: 80%;
    background-color: #086AD8;
  }
  .mobile-navigation-icon.white-md-icon:hover i:after {
    background-color: #086AD8;
    width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .is-sticky .white-md-icon i {
    background-color: #333;
  }
  .is-sticky .white-md-icon i:before {
    background-color: #333;
  }
  .is-sticky .white-md-icon i:after {
    background-color: #333;
  }
}

.page-open-off-sidebar:hover i {
  width: 100%;
}

.page-open-off-sidebar:hover i:before {
  width: 100%;
}

.page-open-off-sidebar:hover i:after {
  width: 100%;
}

.page-open-off-sidebar i {
  width: 100%;
  background-color: #333;
}

.page-open-off-sidebar i:before {
  width: 70%;
  background-color: #333;
}

.page-open-off-sidebar i:after {
  width: 70%;
  background-color: #333;
}

.hidden-icons-menu {
  margin-left: 15px;
}

.hidden-icons-menu a {
  color: #6D70A6;
  font-size: 20px;
}

.hidden-icons-menu a:hover {
  color: #086AD8;
}

/*================================ 
    - Mobile Menu Overlay Css
==================================*/
.mobile-menu-overlay, .page-oppen-off-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  overflow: auto;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  cursor: url(../images/icons/light-close.png) 16 16, pointer;
  visibility: hidden;
  opacity: 0;
}

.mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
  transform: translateX(120%);
  width: 400px;
  height: 100%;
  float: right;
  cursor: default;
  background: #086AD8;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.mobile-menu-overlay.active, .page-oppen-off-sidebar.active {
  visibility: visible;
  opacity: 1;
}

.mobile-menu-overlay.active .mobile-menu-overlay__inner, .page-oppen-off-sidebar.active .mobile-menu-overlay__inner {
  transform: translateX(0%);
}

.mobile-menu-overlay__header, .page-oppen-off-sidebar__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon, .page-oppen-off-sidebar__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover {
  color: #086AD8;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover:before, .mobile-menu-overlay__header .mobile-navigation-close-icon:hover:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.mobile-menu-overlay__body, .page-oppen-off-sidebar__body {
  padding: 20px 40px 100px;
}

.mobile-menu-overlay__body .offcanvas-navigation, .page-oppen-off-sidebar__body .offcanvas-navigation {
  /* onepage style */
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a {
  display: block;
  color: #ffffff;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before {
  content: '\f106';
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 12px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 18px;
  font-family: "Font Awesome 5 Pro";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu {
  padding: 12px 0 14px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a {
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 6px;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation--onepage > ul > li.active > a, .page-oppen-off-sidebar__body .offcanvas-navigation--onepage > ul > li.active > a {
  font-weight: 700;
}

/*======================================
    - offset search
======================================*/
.page-oppen-off-sidebar {
  right: 0;
}

.page-oppen-off-sidebar__inner {
  transform: translateX(100%);
  width: 400px;
  float: right;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.page-oppen-off-sidebar.active .page-oppen-off-sidebar__inner {
  transform: translateX(0%);
}

.page-oppen-off-sidebar__header {
  background: #ffffff;
  padding: 15px 35px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item {
  display: flex;
  margin-bottom: 12px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item .icon {
  color: #6d70a6;
  min-width: calc(1em + 18px);
  font-size: 16px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item a:hover {
  color: #38cb89;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list {
  overflow: hidden;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li {
  width: 50%;
  float: left;
  margin-bottom: 8px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li a {
  line-height: 17px;
}

/*===============================
    - search overlay
===============================*/
.search-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transform: translateY(100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__inner {
  width: 100%;
  height: 100%;
  cursor: default;
  background: #ffffff;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-overlay.active {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.search-overlay__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.search-overlay__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 48px;
  width: 48px;
  line-height: 48px;
  display: inline-block;
}

.search-overlay__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 28px;
  left: 0px;
  content: '';
  width: 42px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 28px;
  left: 0px;
  content: '';
  width: 42px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__header .mobile-navigation-close-icon:hover {
  color: #086AD8;
}

.search-overlay__header .mobile-navigation-close-icon:hover:before, .search-overlay__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.search-overlay__body {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 75px;
}

.search-overlay__form {
  position: relative;
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
}

.search-overlay__form input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #086AD8;
  border-radius: 0;
  padding: 15px 50px 15px 0;
  width: 100%;
  color: #ffffff;
  font-size: 72px;
  height: 100px;
}

@media only screen and (max-width: 767px) {
  .search-overlay__form input {
    font-size: 30px;
    height: 60px;
  }
}

.search-overlay__form input::placeholder {
  color: #086AD8;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__form input[type="text"]:focus {
  color: #086AD8;
  border-color: #086AD8;
}

.header__navigation.preview-menu {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.header__navigation.preview-menu .navigation-menu-right {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.preview-header-inner {
  padding: 20px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .preview-header-inner {
    padding: 0;
  }
}

.preview-header-inner.is-sticky {
  padding: 0px 0;
}

.preview-header-inner.is-sticky .header__navigation.preview-menu ul > li > a {
  color: #333;
}

.preview-header-inner.is-sticky .header__actions--preview .ht-btn {
  background: transparent;
  border: 1px solid #086AD8;
  color: #086AD8;
  line-height: 42px;
  height: 43px;
}

.preview-header-inner .header__actions--preview .ht-btn {
  background: #086AD8;
  border: 1px solid #086AD8;
  color: #ffffff;
}

.preview-header-inner .header__actions--preview .ht-btn:hover {
  background: #ffffff;
  color: #086AD8;
}

/*=====  End of Header  ======*/
/*=========================================
=               06. Footer Css            =
===========================================*/
.reveal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .reveal-footer {
    position: static;
  }
}

.footer-widget-wrapper {
  margin-bottom: -35px;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget__title {
  margin-top: -3px;
  color: #333333;
}

.footer-widget__list li {
  margin-bottom: 10px;
}

.footer-widget__list li:last-child {
  margin-bottom: 0;
}

.footer-widget__list li .image_btn {
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.1);
  overflow: hidden;
}

.social-group__list .ht-social-networks.tilte-style .link-text {
  color: #CCCCCC;
}

.social-group__list .ht-social-networks.tilte-style .link-text::before {
  background: rgba(255, 255, 255, 0.2);
}

.social-group__list .ht-social-networks.tilte-style .link-text:hover {
  color: #ffffff;
}

.social-group__list .ht-social-networks.tilte-style .social-link::before {
  color: #ccc;
}

.copyright-text {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .copyright-text {
    margin-bottom: 15px;
  }
}

.bg-mediumslateblue {
  background-color: #412977;
}

ul.footer-widget__horizontal-list {
  margin-bottom: 20px;
}

ul.footer-widget__horizontal-list li {
  display: inline-block;
  margin: 10px;
  color: #ffffff;
}

.ht-social-white.ht-social-networks .item {
  color: #fff;
}

/*=====  End of footer ======*/
/*=======================================
=            07. Sidebar Css           =
=========================================*/
.page-sidebar-right {
  padding-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-sidebar-right {
    padding-left: 0;
  }
}

.page-sidebar-left {
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-sidebar-left {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-sidebar-right, .page-sidebar-left {
    margin-top: 60px;
  }
}

.sidebar-widget + .sidebar-widget {
  margin-top: 40px;
}

.sidebar-widget-title {
  margin-bottom: 20px;
  font-weight: 500;
}

.widget-search {
  position: relative;
}

.widget-search input {
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 20px;
  padding-right: 20px;
  height: 56px;
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  padding-right: 72px;
}

.widget-search .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: 0;
  border-radius: 0 5px 5px 0;
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: none;
  box-shadow: none;
  transform: none;
}

.widget-search .search-submit:hover {
  color: #ffffff;
  background: #086AD8;
}

.widget-blog-recent-post ul li:first-child a {
  margin-top: -16px;
}

.widget-blog-recent-post ul li a {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 16px 0 16px 30px;
}

.widget-blog-recent-post ul li a::before, .widget-blog-recent-post ul li a::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.widget-blog-recent-post ul li a::after, .widget-blog-recent-post ul li a::before {
  content: '\f30b';
  position: absolute;
  top: 22px;
  left: 0;
}

.widget-blog-recent-post ul li a::before {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.widget-blog-recent-post ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.widget-blog-recent-post ul li a:hover::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.widget-tag .ht-btn {
  color: #ababab;
  background: #f5f5f5;
  margin-right: 10px;
  font-weight: 500;
  margin-bottom: 10px;
}

.widget-tag .ht-btn:hover {
  color: #ffffff;
  background: #086AD8;
}

.widget-product-item {
  overflow: hidden;
  margin-bottom: 20px;
}

.widget-product-item .thumbnail {
  max-width: 150px;
  float: left;
  margin-right: 20px;
  margin-left: 0;
  width: 80px;
}

.widget-product-item .info .product-title {
  font-weight: 400;
  font-size: 16px;
}

.widget-product-item .info .star-rating a {
  display: inline-block;
  color: #ffb805;
  line-height: 1;
  font-size: 14px;
  margin: 0;
}

.widget-product-item .info .price-box {
  margin-top: 5px;
}

.widget-product-item .info .price-box .old-price {
  font-size: .88em;
  font-weight: 400;
  color: #b6b7d2;
  text-decoration: line-through;
}

.widget-product-item .info .price-box .new-price {
  font-size: 14px;
  font-weight: 500;
  color: #6d70a6;
}

/*=====  End of Sidebar  ======*/
/*=============================================
=            08. Button Css           =
=============================================*/
.ht-btn {
  font-size: 15px;
  font-weight: 500;
  border-style: solid;
  border-radius: 5px;
  border-color: transparent;
  padding: 0 36px;
  text-align: center;
  color: #ffffff;
  background: #086AD8;
  border-width: 1px;
  transform: translateY(0px);
}

.ht-btn:hover, .ht-btn:active, .ht-btn:focus {
  color: #ffffff;
  background-image: #086AD8;
  transform: translateY(-3px);
}

.ht-btn.ht-btn-xs {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 20px;
}

.ht-btn.ht-btn-sm {
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}

.ht-btn.ht-btn-md {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 54px;
  height: 54px;
}

.ht-btn.ht-btn-lg {
  padding: 0 40px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
}

.ht-btn.ht-btn-custom {
  min-width: 280px;
}

.ht-btn.ht-btn-full {
  width: auto;
  display: block;
}

.ht-btn--outline {
  border-width: 1px;
  line-height: 54px;
  background: transparent;
  border-color: #086AD8;
  color: #086AD8;
}

.ht-btn--outline:hover, .ht-btn--outline:focus, .ht-btn--outline:active {
  transform: translateY(-3px);
  border-color: transparent;
  background: #086AD8;
  color: #ffffff;
}

.ht-btn--solid {
  border-width: 1px;
  line-height: 54px;
  background: transparent;
  border-color: #ddd;
  color: #086AD8;
}

.ht-btn--solid:hover, .ht-btn--solid:focus, .ht-btn--solid:active {
  transform: translateY(-3px);
  border-color: transparent;
  background: #086AD8;
  color: #ffffff;
}

.ht-btn--circle {
  border-radius: 30px;
}

.ht-btn--no-round {
  border-radius: 0px;
}

.ht-btn.ht-btn-default {
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  width: 180px;
}

.ht-btn .btn-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  font-size: 20px;
  margin-left: 10px;
}

.btn {
  font-size: 15px;
  font-weight: 500;
  border-style: solid;
  border-radius: 5px;
  border-color: transparent;
  border: 1px solid transparent;
  padding: 0 36px;
  text-align: center;
  transition: all 0.4s ease-in-out;
  height: 54px;
  line-height: 54px;
  letter-spacing: 0em;
  color: #ffffff;
  background: #086AD8;
  transform: translateY(0px);
}

.btn:hover, .btn:active, .btn:focus {
  font-weight: 500;
  color: #ffffff;
  background: #086AD8;
  transform: translateY(-3px);
}

.btn--secondary {
  background-image: none;
  background-color: #d2a98e;
}

.btn--secondary:hover {
  background-image: none;
  background: #d2a98e;
}

.btn--black {
  background-image: none;
  background-color: #000000;
}

.btn--black:hover {
  background-image: none;
  background: #000000;
}

.btn--white {
  background-image: none;
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
}

.btn--white:hover, .btn--white:focus {
  background-image: none;
  background: #ffffff;
  color: #000000;
}

.btn--gradient {
  background-image: none;
  background: linear-gradient(150deg, #FF3F85 0, #FAA720 100%) !important;
}

.btn--gradient:hover {
  background-image: none;
  background: linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
}

.btn--link {
  color: #086AD8;
}

.btn--link:hover {
  transform: translateY(-5px);
}

.btn.ht-btn-default {
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  width: 180px;
}

.hover-style-link {
  position: relative;
}

.hover-style-link:after {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #d2a98e;
}

.hover-style-link:hover {
  color: #d2a98e;
}

.hover-style-link:hover:after {
  left: 0;
  width: 100%;
  right: auto;
}

.hover-style-link--secondary {
  color: #d2a98e;
}

.hover-style-link--secondary:after {
  background-color: #d2a98e;
}

.hover-style-link--secondary:hover {
  color: #d2a98e;
}

.hover-style-link--white {
  color: #ffffff;
}

.hover-style-link--white:after {
  background-color: #ffffff;
}

.hover-style-link--white:hover {
  color: #ffffff;
}

.hover-style-link--sub-color {
  color: #6d70a6;
}

.hover-style-link--sub-color:after {
  background-color: #6d70a6;
}

.hover-style-link--sub-color:hover {
  color: #6d70a6;
}

.hover-style-default {
  position: relative;
}

.hover-style-default:after {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #086AD8;
}

.hover-style-default:hover {
  color: #086AD8;
}

.hover-style-default:hover:after {
  left: 0;
  width: 100%;
  right: auto;
}

/* Images Button */
.image-btn {
  border: 0;
  box-shadow: none;
  background: none;
  border-radius: 5px;
  overflow: hidden;
}

/* video pay link */
.video-play i {
  color: #d2a98e;
}

.video-play span {
  font-weight: 500;
  letter-spacing: 3px;
  margin-left: 10px;
}

/* button wrapper */
.button-wrapper > a {
  border: none;
}

@media only screen and (max-width: 479px) {
  .button-wrapper > a {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .button-wrapper > a:last-child {
    margin-bottom: 0;
  }
}

/* Text Button*/
.btn-text {
  color: #086AD8;
}

.btn-text:hover {
  color: #d2a98e;
}

.single-button-group h5 {
  margin-bottom: 10px;
}

.single-button-group .button-group-wrap {
  margin-left: -10px;
  margin-right: -10px;
}

.single-button-group a {
  margin: 10px;
}

.single-button-group a:hover, .single-button-group a:active, .single-button-group a:focus {
  transform: translateY(-3px);
}

/*=====  End of Button  ======*/
/*=============================================
=            09. Accordions Css           =
=============================================*/
.faq-wrapper .panel.panel-default {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.faq-wrapper .card, .faq-wrapper .card-header {
  border-radius: 0;
}

.faq-wrapper .card {
  border-bottom: none;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
}

.faq-wrapper .card:last-child {
  margin-bottom: 0;
}

.faq-wrapper .card-header {
  padding: 0;
  border-bottom: 0;
  background-color: #ffffff;
}

.faq-wrapper .card-header h5 button {
  border: none;
  width: 100%;
  text-align: left;
  color: #000000;
  padding: 20px 30px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  border-radius: 5px;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button {
    padding-left: 15px;
    padding-right: 20px;
  }
}

.faq-wrapper .card-header h5 button span {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button span {
    right: 15px;
  }
}

.faq-wrapper .card-header h5 button span i {
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.faq-wrapper .card-header h5 button:hover, .faq-wrapper .card-header h5 button:focus {
  text-decoration: none;
  color: #000000;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] {
  color: #ffffff;
  background: #d2a98e;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] span i:nth-child(2) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"]:before {
  height: 100%;
}

.faq-wrapper .card-header h5 button[aria-expanded="false"] span i:nth-child(1) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-body {
  padding: 23px 30px 29px;
}

.faq-two-wrapper .panel.panel-default {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.faq-two-wrapper .card, .faq-two-wrapper .card-header {
  border-radius: 0;
}

.faq-two-wrapper .card {
  border-bottom: none;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #ebebeb;
}

.faq-two-wrapper .card:last-child {
  margin-bottom: 0;
}

.faq-two-wrapper .card-header {
  padding: 0;
  border-bottom: 0;
  background-color: #ffffff;
}

.faq-two-wrapper .card-header h5 button {
  font-size: 24px;
  border: none;
  width: 100%;
  text-align: left;
  color: #ababab;
  padding: 20px 35px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  line-height: 1.2;
  font-weight: 700;
  border-radius: 5px;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .faq-two-wrapper .card-header h5 button {
    padding-left: 25px;
    padding-right: 10px;
  }
}

.faq-two-wrapper .card-header h5 button span {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 479px) {
  .faq-two-wrapper .card-header h5 button span {
    right: 15px;
  }
}

.faq-two-wrapper .card-header h5 button span i {
  font-size: 24px;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.faq-two-wrapper .card-header h5 button:hover, .faq-two-wrapper .card-header h5 button:focus {
  text-decoration: none;
  color: #086AD8;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"] {
  color: #086AD8;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"] span i {
  color: #d2a98e;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"] span i:nth-child(2) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"]:before {
  height: 100%;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="false"] span i:nth-child(1) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-two-wrapper .card-body {
  padding: 0px 30px 29px;
}

.faq-custom-col {
  max-width: 570px;
  float: right;
  width: 100%;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-custom-col {
    float: inherit;
    margin: 0 auto 30px;
  }
}

/*=============================================
=           10. Call To Action Css             =
=============================================*/
.cta-bg-image_one {
  background-color: #454545;
  background-image: url(../images/patterns/mitech-call-to-action-image-global.png);
  background-repeat: no-repeat;
  background-position: top 35% right -68px;
}

.cta-bg-image_two {
  background-color: #f6f2ed;
  background-image: url(../images/patterns/mitech-home-appointment-call-to-action-bg-image.png);
  background-repeat: no-repeat;
  background-position: top right;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .cta-content {
    text-align: center;
    margin-bottom: 10px;
  }
}

.cta-button-group--one .btn-one {
  color: #086AD8;
  margin: 10px;
}

.cta-button-group--one .btn-one:hover {
  background: #086AD8;
  color: #ffffff;
}

.cta-button-group--one .btn-two {
  margin: 10px;
}

.cta-button-group--one .btn-two:hover {
  background: #086AD8;
  color: #ffffff;
}

.cta-button-group--two .btn-one {
  background: #086AD8;
  color: #ffffff;
  margin: 10px;
}

.cta-button-group--two .btn-two {
  margin: 10px;
}

.talk-message-box {
  max-width: 630px;
  margin: auto;
  border-radius: 5px;
  background-color: #e7edfa;
  padding: 28px 35px;
  position: relative;
}

.talk-message-box .message-icon {
  font-size: 24px;
  width: 64px;
  height: 64px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 64px;
  background: #fff;
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
  position: absolute;
  top: -35px;
  left: -35px;
  color: #086AD8;
}

@media only screen and (max-width: 767px) {
  .talk-message-box .message-icon {
    top: -43px;
    left: 5px;
  }
}

.talk-message-box-content {
  display: flex;
  justify-content: space-between;
}

.talk-message-box-content .heading {
  max-width: 350px;
}

@media only screen and (max-width: 767px) {
  .talk-message-box-content .heading {
    max-width: 280px;
  }
}

@media only screen and (max-width: 575px) {
  .talk-message-box-content {
    flex-direction: column;
  }
  .talk-message-box-content .heading {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

/*=====  End of cta   ======*/
/*======================================
=          11. Countdown Css            =
=======================================*/
.countdown-deals {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 767px) {
  .countdown-deals {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 575px) {
  .countdown-deals {
    grid-template-columns: repeat(2, 1fr);
  }
}

.countdown-deals.counter-style--one .single-countdown {
  display: inline-block;
}

.countdown-deals.counter-style--one .single-countdown .single-countdown__time {
  display: block;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  color: #086AD8;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 40px;
  }
}

.countdown-deals.counter-style--one .single-countdown .single-countdown__text {
  display: block;
  color: #6d70a6;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.countdown-deals.counter-style--two .single-countdown {
  display: inline-block;
}

.countdown-deals.counter-style--two .single-countdown .single-countdown__time {
  display: block;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__time {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__time {
    font-size: 40px;
  }
}

.countdown-deals.counter-style--two .single-countdown .single-countdown__text {
  display: block;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.countdown-deals.counter-style--four .single-countdown {
  display: inline-block;
  width: 70px;
  margin: auto;
}

.countdown-deals.counter-style--four .single-countdown .single-countdown__time {
  display: block;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 90px;
  line-height: 90px;
  color: #000000;
}

.countdown-deals.counter-style--four .single-countdown .single-countdown__text {
  display: block;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}

.time_circles > div {
  position: absolute;
  text-align: center;
  top: 0% !important;
  transform: translateY(50%);
}

.time_circles .textDiv_Days, .textDiv_Hours, .textDiv_Minutes, .textDiv_Seconds {
  color: #222222;
  font-size: 30px !important;
}

@media only screen and (max-width: 575px) {
  .time_circles .textDiv_Days, .textDiv_Hours, .textDiv_Minutes, .textDiv_Seconds {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .time_circles .textDiv_Days, .textDiv_Hours, .textDiv_Minutes, .textDiv_Seconds {
    font-size: 20px !important;
  }
}

.time_circles > div > h4 {
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  font-size: 13px !important;
  color: #6d70a6;
}

@media only screen and (max-width: 575px) {
  .time_circles > div > h4 {
    font-size: 11px !important;
  }
}

/*======================================
=         12. Counters Css              =
=======================================*/
.fun-fact-grid-4 {
  border-right: 1px solid #ededed;
  display: flex;
  flex-wrap: wrap;
}

.fun-fact-grid-4 .grid-item {
  width: 25%;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .fun-fact-grid-4 .grid-item {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.fun-fact-grid-4 .grid-item::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  background: #ededed;
  width: 1px;
}

.fun-fact-grid-4 .grid-item::after {
  position: absolute;
  content: '';
  top: -1px;
  left: 0;
  right: 0;
  background: #ededed;
  height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fun-fact-grid-4 .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .fun-fact-grid-4 .grid-item {
    width: 100%;
  }
}

.fun-fact--one .fun-fact__title {
  color: #333333;
  font-weight: 500;
  margin-bottom: 10px;
}

.fun-fact--one .fun-fact__count {
  font-size: 64px;
  font-weight: 600;
  line-height: 1.41;
  color: #086AD8;
}

.fun-fact--one .fun-fact__text {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #d2a98e;
}

.fun-fact--two {
  margin-bottom: 30px;
}

.fun-fact--two .fun-fact__count {
  font-size: 56px;
  color: #ffffff;
  margin-bottom: 20px;
  line-height: 1;
  font-weight: 700;
}

.fun-fact--two .fun-fact__text {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  letter-spacing: 2px;
}

.fun-fact--three {
  position: relative;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fun-fact--three {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fun-fact--three {
    margin-bottom: 0px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .fun-fact--three {
    margin-bottom: 0px;
    margin-top: 30px;
  }
}

.fun-fact--three .fun-fact__count {
  color: #086AD8;
  margin-bottom: 19px;
  font-size: 56px;
  font-weight: 400;
  line-height: 1;
}

.fun-fact--three .fun-fact__text {
  font-size: 14px;
  text-transform: uppercase;
  color: #7e7e7e;
}

.fun-fact--four {
  position: relative;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fun-fact--four {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fun-fact--four {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .fun-fact--four {
    margin-bottom: 30px;
  }
}

.fun-fact--four .icon {
  border-radius: 10px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: #fff;
  font-size: 30px;
  text-align: center;
  margin-right: 20px;
  box-shadow: 0 0 15px rgba(51, 51, 51, 0.05);
  color: #002FA6;
}

.fun-fact--four .fun-fact-text {
  text-align: left;
}

.fun-fact--four .fun-fact__count {
  color: #000000;
  margin-bottom: 10px;
  font-size: 34px;
  font-weight: 600;
  line-height: 1;
}

.fun-fact--four .fun-fact__text {
  font-size: 14px;
  text-transform: uppercase;
  color: #333;
  letter-spacing: 2px;
}

.fun-fact--five {
  margin-bottom: 40px;
}

.fun-fact--five .fun-fact__count {
  font-size: 46px;
  color: #ffffff;
  margin-bottom: 20px;
  line-height: 1;
  font-weight: 700;
}

.fun-fact--five .fun-fact__text {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  letter-spacing: 2px;
}

.modern-number-01 h2 {
  position: relative;
  font-size: 12px;
  line-height: 2em;
  letter-spacing: 2px;
  color: #ababab;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
}

.modern-number-01 span.mark-text {
  font-size: 180px;
  line-height: .8;
  font-weight: 900;
  margin: 0 5px -5px -5px;
  color: #d2a98e;
}

@media only screen and (max-width: 767px) {
  .modern-number-01 span.mark-text {
    font-size: 140px;
  }
}

.modern-number-01 h3 {
  margin-top: 25px;
  font-size: 38px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-number-01 h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-number-01 h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-number-01 h3 {
    font-size: 24px;
  }
}

.cybersecurity-about-text .text {
  font-size: 18px;
  margin-bottom: 20px;
}

.managed-it-fun-fact-wrapper .fun-fact-grid-4 .grid-item {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

.managed-it-fun-fact-wrapper .fun-fact-grid-4 .grid-item::after {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .managed-it-fun-fact-wrapper .fun-fact-grid-4 .grid-item::after {
    display: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .managed-it-fun-fact-wrapper .fun-fact-grid-4 .grid-item::after {
    display: inherit;
  }
}

/*=============================================
=              13. Box Icon Css              =
=============================================*/
.ht-box-icon.style-01 .icon-box-wrap {
  padding: 36px 10px 26px;
  border-radius: 5px;
  background: #fff;
  border: 2px solid #eee;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  margin-top: 30px;
}

.ht-box-icon.style-01 .icon-box-wrap .icon {
  height: 65px;
  text-align: center;
  width: 65px;
  margin: auto;
  margin-bottom: 29px;
  color: #086AD8;
}

.ht-box-icon.style-01 .icon-box-wrap .icon svg > * {
  stroke: #086AD8;
}

.ht-box-icon.style-01 .icon-box-wrap .content {
  text-align: center;
}

.ht-box-icon.style-01 .icon-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-icon.style-01 .icon-box-wrap .content .service_text {
  color: #696969;
}

.ht-box-icon.style-01:hover .icon-box-wrap {
  border-color: #fff;
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.ht-box-icon.style-01 .feature-btn a {
  color: #086AD8;
  margin-top: 20px;
}

.ht-box-icon.style-01 .feature-btn a:hover {
  color: #d2a98e;
}

.ht-box-icon.style-02 .icon-box-wrap {
  padding: 30px 15px;
  border-radius: 5px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  display: flex;
}

.ht-box-icon.style-02 .icon-box-wrap .icon {
  min-width: 80px;
  margin-right: 10px;
  color: #333333;
}

.ht-box-icon.style-02 .icon-box-wrap .icon .svg-icon {
  width: 65px;
  height: 65px;
}

.ht-box-icon.style-02 .icon-box-wrap .icon svg * {
  stroke: currentColor;
}

.ht-box-icon.style-02 .icon-box-wrap .content {
  text-align: left;
}

.ht-box-icon.style-02 .icon-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-icon.style-02 .icon-box-wrap .content .service_text {
  color: #696969;
}

.ht-box-icon.style-02:hover .icon-box-wrap {
  border-color: #fff;
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.ht-box-icon.style-02 .feature-btn a {
  color: #086AD8;
  margin-top: 20px;
}

.ht-box-icon.style-02 .feature-btn a:hover {
  color: #d2a98e;
}

.ht-box-icon.style-03 .icon-box-wrap {
  padding: 30px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.ht-box-icon.style-03 .icon-box-wrap .content-header {
  display: flex;
  align-items: center;
}

.ht-box-icon.style-03 .icon-box-wrap .content-header .icon {
  flex-shrink: 0;
  font-size: 48px;
  font-weight: 48px;
  min-width: 60px;
  margin-right: 10px;
  color: #d2a98e;
}

.ht-box-icon.style-03 .icon-box-wrap .content-header .heading {
  color: #086AD8;
  margin-bottom: 10px;
}

.ht-box-icon.style-03 .icon-box-wrap .content .service_text {
  color: #696969;
}

.ht-box-icon.style-03:hover .icon-box-wrap {
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

@media only screen and (max-width: 575px) {
  .ht-box-icon.style-03 .icon-box-wrap {
    padding: 37px 10px;
  }
  .ht-box-icon.style-03 .icon-box-wrap .image {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}

.ht-box-icon.style-04 .icon-box-wrap {
  padding: 19px 24px 30px;
  border-radius: 5px;
  background: #edf2fe;
  position: relative;
  z-index: 1;
  height: 100%;
}

.ht-box-icon.style-04 .icon-box-wrap .icon {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 48px;
  margin: 0;
  flex-shrink: 0;
  line-height: 1;
  color: #eddcd1;
  z-index: -1;
}

.ht-box-icon.style-04 .icon-box-wrap .content .sub-heading {
  margin-bottom: 5px;
}

.ht-box-icon.style-04 .icon-box-wrap .content .heading {
  font-weight: 700;
  margin-bottom: 10px;
}

.ht-box-icon.style-04 .icon-box-wrap.color-one {
  background-color: #faf6f3;
}

.ht-box-icon.style-04 .icon-box-wrap.color-one .icon {
  color: #eddcd1;
}

.ht-box-icon.style-04 .icon-box-wrap.color-one .content .sub-heading {
  color: #d2a98e;
}

.ht-box-icon.style-04 .icon-box-wrap.color-two {
  background-color: #edf2fe;
}

.ht-box-icon.style-04 .icon-box-wrap.color-two .icon {
  color: #b9cefd;
}

.ht-box-icon.style-04 .icon-box-wrap.color-two .content .sub-heading {
  color: #4e84fa;
}

.ht-box-icon.style-04 .icon-box-wrap.color-three {
  background-color: #f0f5f4;
}

.ht-box-icon.style-04 .icon-box-wrap.color-three .icon {
  color: #c7dbd7;
}

.ht-box-icon.style-04 .icon-box-wrap.color-three .content .sub-heading {
  color: #72a499;
}

.ht-box-icon.style-04 .icon-box-wrap.color-four {
  background-color: #fff5f6;
}

.ht-box-icon.style-04 .icon-box-wrap.color-four .icon {
  color: #f5b4bd;
}

.ht-box-icon.style-04 .icon-box-wrap.color-four .content .sub-heading {
  color: #e7445b;
}

.ht-box-icon.style-04 .icon-box-wrap.color-five {
  background-color: #fcf7ee;
}

.ht-box-icon.style-04 .icon-box-wrap.color-five .icon {
  color: #f5e1be;
}

.ht-box-icon.style-04 .icon-box-wrap.color-five .content .sub-heading {
  color: #e5b35d;
}

.feature-list-button-box .ht-btn {
  margin: 10px;
}

.claim-grid-group {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .claim-grid-group {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .claim-grid-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .claim-grid-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 479px) {
  .claim-grid-group {
    grid-template-columns: repeat(1, 1fr);
  }
}

/*=============================================
=              14. Box Images Css              =
=============================================*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-grid-image-box .section-space--mt_60 {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-grid-image-box .section-space--mt_60 {
    margin-top: 30px;
  }
}

.ht-box-images.style-01 .image-box-wrap {
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
  background: #fff;
  border-radius: 5px;
  padding: 50px 30px 63px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  margin-top: 60px;
}

.ht-box-images.style-01 .image-box-wrap .box-image {
  text-align: center;
  margin-bottom: 41px;
  color: #086AD8;
}

.ht-box-images.style-01 .image-box-wrap .content {
  text-align: center;
}

.ht-box-images.style-01 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-01 .image-box-wrap .content .service_text {
  color: #696969;
}

.ht-box-images.style-01:hover .icon-box-wrap {
  border-color: #fff;
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.ht-box-images.style-01 .circle-arrow {
  position: absolute;
  left: 50%;
  bottom: 0px;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  height: 56px;
  width: 56px;
}

.ht-box-images.style-01 .circle-arrow .middle-dot {
  pointer-events: none;
  content: '';
  position: absolute;
  top: -12px;
  left: -12px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #086ad8;
  opacity: 0;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.ht-box-images.style-01 .circle-arrow a {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  background: #d2a98e;
}

.ht-box-images.style-01:hover .circle-arrow a {
  background: #086AD8;
}

.ht-box-images.style-01:hover .circle-arrow .middle-dot {
  -webkit-animation: boxImageAnimationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
  animation: boxImageAnimationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
  animation-iteration-count: 1;
  animation-fill-mode: none;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ht-box-images.style-02 {
  display: block;
}

.ht-box-images.style-02 .image-box-wrap {
  box-shadow: 0 0 40px 5px rgba(51, 51, 51, 0.1);
  background: #fff;
  border-radius: 5px;
  padding: 30px 38px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ht-box-images.style-02 .image-box-wrap .box-image {
  flex-shrink: 0;
  margin-right: 30px;
}

.ht-box-images.style-02 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.67;
  margin-bottom: 0;
}

.ht-box-images.style-02 .image-box-wrap .content .text {
  color: #696969;
}

.ht-box-images.style-02:hover {
  transform: translateY(-5px);
}

.ht-box-images.style-03 {
  box-shadow: 0 0 41px rgba(0, 0, 0, 0.03);
  background: #fff;
  padding: 40px 30px 65px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ht-box-images.style-03 .image-box-wrap {
  max-width: 340px;
  margin: 0 auto;
}

.ht-box-images.style-03 .image-box-wrap .box-image {
  margin-bottom: 36px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-03 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.67;
  margin-bottom: 0;
}

.ht-box-images.style-03 .image-box-wrap .content .text {
  color: #696969;
}

.ht-box-images.style-03:hover .box-image {
  transform: translateY(-5px);
}

.ht-box-images.style-03:hover .content .heading {
  color: #002FA6;
}

.ht-box-images.style-04 {
  box-shadow: 0 0 30px rgba(51, 51, 51, 0.05);
  background: #fff;
  padding: 25px 30px 34px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-04 .image-box-wrap .box-image {
  margin-bottom: 9px;
}

.ht-box-images.style-04 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.67;
  margin-bottom: 0;
}

.ht-box-images.style-04 .image-box-wrap .content .text {
  color: #696969;
}

.ht-box-images.style-04:hover {
  transform: translateY(-5px);
}

.ht-box-images.style-05 {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-05 .image-box-wrap {
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
  background: #ffffff;
  border-radius: 5px;
  padding: 50px 30px 63px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: 50px;
}

.ht-box-images.style-05 .image-box-wrap .box-image {
  position: relative;
  margin-bottom: 41px;
  height: 100px;
}

.ht-box-images.style-05 .image-box-wrap .box-image .hover-images {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.ht-box-images.style-05 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-05 .image-box-wrap .content .service_text {
  color: #696969;
}

.ht-box-images.style-05 .image-box-wrap .content .box-images-arrow {
  display: inline-block;
  margin-top: 27px;
  font-size: 14px;
  font-weight: 500;
}

.ht-box-images.style-05 .image-box-wrap .content .box-images-arrow a {
  color: #086AD8;
}

.ht-box-images.style-05:hover {
  transform: translateY(-5px);
}

.ht-box-images.style-05:hover .image-box-wrap {
  background: #002FA6;
}

.ht-box-images.style-05:hover .box-image .default-image {
  opacity: 0;
  visibility: hidden;
}

.ht-box-images.style-05:hover .box-image .hover-images {
  opacity: 1;
  visibility: visible;
}

.ht-box-images.style-05:hover .content .heading {
  color: #ffffff;
}

.ht-box-images.style-05:hover .content .text {
  color: #ffffff;
}

.ht-box-images.style-05:hover .content .box-images-arrow a {
  color: #d2a98e;
}

.ht-box-images.style-06 {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-06 .image-box-wrap {
  box-shadow: 0 0 40px 5px rgba(51, 51, 51, 0.1);
  background: #fff;
  border-radius: 5px;
  padding: 98px 35px 88px;
  overflow: hidden;
  position: relative;
  margin-top: 30px;
}

.ht-box-images.style-06 .image-box-wrap .box-image {
  position: relative;
  width: 162px;
  height: 162px;
  border-radius: 50%;
  margin: 0 auto 2px;
  text-align: center;
}

.ht-box-images.style-06 .image-box-wrap .box-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(160deg, #fbf7f4 0%, #fefdfd 100%);
  border-radius: 50%;
  transform: translateY(-46px);
}

.ht-box-images.style-06 .image-box-wrap .box-image .default-image {
  position: relative;
  z-index: 2;
}

.ht-box-images.style-06 .image-box-wrap .content {
  text-align: center;
}

.ht-box-images.style-06 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-06 .image-box-wrap .content .service_text {
  color: #696969;
}

.ht-box-images.style-06 .image-box-wrap .content .box-images-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 56px;
  line-height: 55px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #eee;
  color: #086AD8;
  /* &:hover {
                        color: $white;
                        &::before {
                            transform: translateY(0);
                        }
                    }*/
}

.ht-box-images.style-06 .image-box-wrap .content .box-images-arrow::before {
  background-color: #086AD8;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.ht-box-images.style-06:hover {
  transform: translateY(-5px);
}

.ht-box-images.style-06:hover .content .box-images-arrow {
  color: #ffffff;
}

.ht-box-images.style-06:hover .content .box-images-arrow::before {
  transform: translateY(0);
}

.ht-box-images.style-07 {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-07 .image-box-wrap {
  padding: 30px 35px 31px;
  border-radius: 4px;
  margin-top: 10px;
}

.ht-box-images.style-07 .image-box-wrap .box-image {
  text-align: center;
  margin-bottom: 24px;
}

.ht-box-images.style-07 .image-box-wrap .content {
  text-align: center;
}

.ht-box-images.style-07 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-07 .image-box-wrap .content .text {
  color: #696969;
}

.ht-box-images.style-07:hover {
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
}

.ht-box-images.style-08 {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-08 .image-box-wrap {
  height: 100%;
  position: relative;
  display: flex;
  margin-top: 50px;
}

.ht-box-images.style-08 .image-box-wrap .box-image {
  flex-shrink: 0;
  margin-right: 24px;
}

.ht-box-images.style-08 .image-box-wrap .content {
  flex-grow: 1;
}

.ht-box-images.style-08 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-08 .image-box-wrap .content .text {
  color: #696969;
}

@media only screen and (max-width: 575px) {
  .ht-box-images.style-08 .image-box-wrap {
    flex-direction: column;
  }
  .ht-box-images.style-08 .image-box-wrap .content {
    margin-top: 20px;
  }
}

.ht-box-images.style-08:hover {
  transform: translateY(-5px);
}

.ht-box-images.style-09 .image-box-wrap {
  border-radius: 5px;
  text-align: center;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 30px 35px 31px;
  border-radius: 5px;
}

.ht-box-images.style-09 .image-box-wrap .box-image {
  text-align: center;
  margin-bottom: 41px;
  color: #086AD8;
}

.ht-box-images.style-09 .image-box-wrap .content {
  text-align: center;
}

.ht-box-images.style-09 .image-box-wrap .content .heading {
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-09 .image-box-wrap .content .more-arrow-link {
  margin-top: 15px;
}

.ht-box-images.style-09 .image-box-wrap .content .more-arrow-link a {
  font-weight: 500;
  color: #086AD8;
  opacity: 0;
  visibility: hidden;
}

.ht-box-images.style-09 .image-box-wrap:hover .more-arrow-link a {
  opacity: 1;
  visibility: visible;
}

.ht-box-images.style-10 {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ht-box-images.style-10 .image-box-wrap {
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
  background: #ffffff;
  border-radius: 5px;
  padding: 50px 30px 63px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ht-box-images.style-10 .image-box-wrap .box-image {
  position: relative;
  margin-bottom: 41px;
  height: 100px;
}

.ht-box-images.style-10 .image-box-wrap .box-image .hover-images {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.ht-box-images.style-10 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-10 .image-box-wrap .content .service_text {
  color: #696969;
}

.ht-box-images.style-10 .image-box-wrap .content .box-images-arrow {
  display: inline-block;
  margin-top: 27px;
  font-size: 14px;
  font-weight: 500;
}

.ht-box-images.style-10 .image-box-wrap .content .box-images-arrow a {
  color: #086AD8;
}

.ht-box-images.style-10:hover {
  transform: translateY(-5px);
}

.image-l-r-box {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.image-box-boder-box::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-image: -webkit-linear-gradient(top, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-image: linear-gradient(to bottom, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-position: left top;
  background-size: 100% 8px;
  background-repeat: repeat-y;
  z-index: 1;
}

.image-box-boder-box::after {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  bottom: -1px;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-image: linear-gradient(to right, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-position: left bottom;
  background-size: 8px 100%;
  background-repeat: repeat-x;
}

.image-box-boder-box .image-box-boder {
  padding: 0;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.image-box-boder-box .image-box-boder::before {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-image: -webkit-linear-gradient(top, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-image: linear-gradient(to bottom, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-position: left top;
  background-size: 100% 8px;
  background-repeat: repeat-y;
}

.image-box-boder-box .image-box-boder::after {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  top: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-image: linear-gradient(to right, #ccc 58.33333%, rgba(0, 0, 0, 0) 0%);
  background-position: left bottom;
  background-size: 8px 100%;
  background-repeat: repeat-x;
}

.image-box-boder-box .image-box-boder:hover .style-09 {
  background: #fff;
  border-radius: 3px;
  transform: scale(1.03);
  z-index: 3;
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
  z-index: 9;
  position: relative;
  transition: all 0.3s ease-in-out;
}

@-webkit-keyframes boxImageAnimationSignal {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  1% {
    opacity: .14;
  }
  20% {
    opacity: .3;
  }
  40% {
    opacity: .4;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes boxImageAnimationSignal {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  1% {
    opacity: .14;
  }
  20% {
    opacity: .3;
  }
  40% {
    opacity: .4;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/*=============================================
=         15. Box Large Images Css            =
=============================================*/
.large-image-grid-style-two .box-large-image__midea {
  position: relative;
  height: 440px;
}

.large-image-grid-style-two .bg-item-images {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.box-large-image__wrap {
  max-width: 370px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .box-large-image__wrap {
    margin: auto;
  }
}

.box-large-image__wrap:hover .box-large-image__box {
  transform: translateY(-5px);
}

.box-large-image__wrap:hover .images-midea img {
  transform: scale(1.1, 1.1);
}

.box-large-image__wrap:hover .images-midea::after {
  background: #002fa6;
  opacity: .8;
}

.box-large-image__wrap:hover .heading-wrap .heading {
  transform: translateY(-100%);
  opacity: 0;
}

.box-large-image__wrap:hover .button-wrapper .btn {
  transform: translateY(0);
  opacity: 1;
}

.box-large-image__box {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.box-large-image__box .button-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  overflow: hidden;
  z-index: 3;
}

.box-large-image__box .button-wrapper .btn {
  background: #fff;
  color: #086ad8;
  transform: translateY(100%);
  opacity: 0;
}

.box-large-image__midea {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.box-large-image__midea .images-midea {
  text-align: center;
}

.box-large-image__midea .images-midea img {
  width: 100%;
  transition: transform 1s;
}

.box-large-image__midea .images-midea::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
  background-image: linear-gradient(-180deg, transparent 39%, #1c0f69 100%);
}

.box-large-image__midea .images-midea .heading-wrap {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 250px;
  margin: 0 auto;
  z-index: 1;
  overflow: hidden;
}

.box-large-image__midea .images-midea .heading-wrap .heading {
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  font-weight: 500;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.box-large-image .box-info {
  position: relative;
  z-index: 2;
  padding: 30px 20px;
  text-align: center;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  height: 100%;
  min-height: 330px;
}

.box-large-image .box-info .heading {
  -webkit-transform: translateY(-100%) translateY(-54px);
  -ms-transform: translateY(-100%) translateY(-54px);
  transform: translateY(-100%) translateY(-54px);
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
}

.box-large-image__content p {
  color: #7e7e7e;
}

.box-large-image__content p strong {
  color: #333;
  font-weight: 500;
}

.box-large-image__two {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}

.box-large-image__two:hover .box-large-image__box {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}

.box-large-image__two:hover .box-large-image__midea::after {
  background: #002fa6;
  opacity: .8;
}

.box-large-image__two:hover .box-info {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.box-large-image__two:hover .box-info .heading {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.box-large-image__two .box-large-image__midea {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  height: 320px;
}

.box-large-image__two .box-large-image__midea img {
  width: 100%;
  transition: transform 1s;
}

.box-large-image__two .box-large-image__midea::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
  background-image: linear-gradient(-180deg, rgba(28, 15, 105, 0) 11%, #000 80%);
}

.box-large-image__two__box {
  -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}

.box-large-image__two__box .box-info {
  position: absolute;
  z-index: 2;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 20px;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  height: 330px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 0;
  -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.box-large-image__two__box .box-info .heading {
  transform: translateY(-100%) translateY(-54px);
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 10px;
}

.box-large-image__two__box .box-info .text {
  color: #ffffff;
}

.box-large-image__two__box .box-info .btn {
  background: #d2a98e;
  margin-top: 40px;
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  padding: 0;
}

.ht-large-box-images.style-03 {
  display: block;
}

.ht-large-box-images.style-03 .large-image-box {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  position: relative;
  margin-bottom: 30px;
}

.ht-large-box-images.style-03 .content {
  padding: 32px 35px;
  padding-bottom: 88px;
  text-align: center;
}

.ht-large-box-images.style-03 .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-large-box-images.style-03 .content .text {
  color: #696969;
}

.ht-large-box-images.style-03 .content .box-images-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 56px;
  line-height: 55px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #eee;
  color: #086AD8;
}

.ht-large-box-images.style-03 .content .box-images-arrow::before {
  background-color: #086AD8;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.ht-large-box-images.style-03:hover {
  transform: translateY(-5px);
}

.ht-large-box-images.style-03:hover .content .heading {
  color: #086AD8;
}

.ht-large-box-images.style-03:hover .content .box-images-arrow {
  color: #ffffff;
}

.ht-large-box-images.style-03:hover .content .box-images-arrow::before {
  transform: translateY(0);
}

.ht-large-box-images.style-04 {
  display: block;
}

.ht-large-box-images.style-04 .large-image-box {
  position: relative;
  margin-bottom: 30px;
}

.ht-large-box-images.style-04 .box-image {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-large-box-images.style-04 .content {
  padding: 32px 35px;
  padding-bottom: 88px;
  text-align: center;
}

.ht-large-box-images.style-04 .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-large-box-images.style-04 .content .text {
  color: #696969;
}

.ht-large-box-images.style-04 .content .box-images-arrow {
  margin-top: 20px;
  color: #086AD8;
}

.ht-large-box-images.style-04:hover .box-image {
  transform: translateY(-5px);
}

/*=============================================
=            16. Brand Logo Css             =
=============================================*/
.brand-logo-slider__one .brand-logo-slider__container {
  padding: 15px 0;
}

.brand-logo-slider__one .brand-logo {
  align-self: center;
  text-align: center;
  position: relative;
  /*&--slider{
            margin-bottom: 0;
        }*/
}

.brand-logo-slider__one .brand-logo__image {
  transition: all .4s linear;
}

.brand-logo-slider__one .brand-logo__image-hover {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8, 0.8);
  transition: all .4s linear;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.brand-logo-slider__one .brand-logo:hover .brand-logo__image {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8, 0.8);
}

.brand-logo-slider__one .brand-logo:hover .brand-logo__image-hover {
  opacity: 1;
  visibility: visible;
  transform: none !important;
}

.brand-logo-slider__two .brand-logo-two {
  align-self: center;
  text-align: center;
  position: relative;
}

.brand-logo-slider__two .brand-logo-two__image {
  transition: all .4s linear;
}

.brand-logo-slider__two .brand-logo-two__image-hover {
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, 20px);
  transition: all .4s linear;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
}

.brand-logo-slider__two .brand-logo-two:hover .brand-logo-two__image {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
}

.brand-logo-slider__two .brand-logo-two:hover .brand-logo-two__image-hover {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}

.brand-logo-slider__two .brand-logo-two--slider {
  margin-bottom: 0;
}

.brand-logo-slider__three .brand-logo {
  align-self: center;
  text-align: center;
  position: relative;
}

.brand-logo-slider__three .brand-logo__image {
  opacity: 0.4;
  transition: all .4s linear;
}

.brand-logo-slider__three .brand-logo:hover .brand-logo__image {
  opacity: 1;
}

.brand-logo-slider__three .brand-logo--slider {
  margin-bottom: 0;
}

.brand-logo-slider__four .brand-logo {
  align-self: center;
  text-align: center;
  position: relative;
}

.brand-logo-slider__four .brand-logo__image {
  opacity: 1;
  transition: all .4s linear;
}

.brand-logo-slider__four .brand-logo:hover .brand-logo__image {
  opacity: 0.4;
}

.brand-logo-slider__four .brand-logo--slider {
  margin-bottom: 0;
}

.brand-logo-slider-area .section-title .heading-text {
  max-width: 700px;
  width: 100%;
  font-weight: 500;
  margin: auto;
}

/*======================================
=          27. Listing Css              =
=======================================*/
.separator-list .list-item {
  position: relative;
  margin-bottom: 6px;
  padding-bottom: 6px;
}

.separator-list .list-item::before {
  position: absolute;
  bottom: 0px;
  left: 0;
  content: '';
  height: 1px;
  width: 100%;
  background: #eee;
}

.separator-list .list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.separator-list .list-item:last-child::before {
  display: none;
}

.separator-list .list-item a {
  line-height: 16px;
}

.check-list .list-item {
  position: relative;
  padding-left: 32px;
  margin-bottom: 10px;
}

.check-list .list-item::before {
  color: #086AD8;
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 0;
  display: block;
  font-size: 13px;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.check-list .list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.check-list .list-item a {
  line-height: 16px;
}

.check-list.check-list-color-two .list-item::before {
  color: #d2a98e;
}

.circle-list .list-item {
  position: relative;
  padding-left: 14px;
  margin-bottom: 12px;
}

.circle-list .list-item::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #999;
}

.circle-list .list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.circle-list .list-item a {
  line-height: 16px;
}

.icon-list .list-item {
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
}

.icon-list .list-item .list__icon {
  color: #086AD8;
  font-size: 16px;
  margin-right: 10px;
}

.icon-list .list-item a {
  line-height: 16px;
}

ol {
  min-width: 45px;
  margin: 0;
  padding-left: 30px;
}

.automatic-numbered-list .list-item {
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
  font-size: 24px;
  color: #333;
  line-height: 1.2;
  font-weight: 400;
}

.automatic-numbered-list .list-item a {
  line-height: 16px;
}

.ht-list.style-auto-numbered-02 .list-item {
  position: relative;
  padding: 0;
  margin-bottom: 62px;
}

.ht-list.style-auto-numbered-02 .list-item:last-child {
  margin-bottom: 0;
}

.ht-list.style-auto-numbered-02 .list-item .link {
  display: block;
}

.ht-list.style-auto-numbered-02 .list-item .link:hover .title {
  color: #086AD8;
}

.ht-list.style-auto-numbered-02 .list-item .link:hover .title::after {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  color: #086AD8;
}

.ht-list.style-auto-numbered-02 .list-item .link:hover .title::before {
  opacity: 0;
  visibility: hidden;
}

.ht-list.style-auto-numbered-02 .list-item .link:hover .marker {
  color: #086AD8;
}

.ht-list.style-auto-numbered-02 .marker {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.2;
  margin-right: 10px;
  min-width: 45px;
  color: #ababab;
  display: inline-block;
  float: left;
}

.ht-list.style-auto-numbered-02 .title-wrap {
  overflow: hidden;
}

.ht-list.style-auto-numbered-02 .title {
  font-size: 24px;
  line-height: 1.2;
  margin-top: 14px;
  padding-top: 20px;
  border-top: 2px solid #ededed;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-right: 15px;
}

.ht-list.style-auto-numbered-02 .title::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  content: '\f178';
  position: absolute;
  top: 38px;
  right: 0;
  color: #333;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-list.style-auto-numbered-02 .title::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  content: '\f178';
  position: absolute;
  top: 38px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/*=============================================
=        18. Gradation Process Css            =
=============================================*/
.icon-process-wrapper {
  display: flex;
  text-align: center;
  margin-bottom: -60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .icon-process-wrapper {
    flex-direction: column;
  }
}

.icon-process-single {
  padding: 0 10px;
  position: relative;
  flex-grow: 1;
  margin-bottom: 60px;
}

.icon-process-single__line {
  position: absolute;
  top: 60px;
  left: 100%;
  transform: translate(-50%, -5px);
  height: 10px;
  width: 100%;
  line-height: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .icon-process-single__line {
    left: 50%;
    bottom: -30px;
    top: auto;
    transform: translate(-50%, 0);
  }
}

.icon-process-single__line .dot {
  display: inline-block;
  vertical-align: middle;
  background: #e4e8f6;
  border-radius: 50%;
  margin: 0 8px;
}

.icon-process-single__line .dot-1 {
  width: 2px;
  height: 2px;
}

.icon-process-single__line .dot-2 {
  width: 4px;
  height: 4px;
}

.icon-process-single__line .dot-3 {
  width: 6px;
  height: 6px;
}

.icon-process-single__line .dot-4 {
  width: 8px;
  height: 8px;
}

.icon-process-single__line .dot-5 {
  width: 10px;
  height: 10px;
}

.icon-process-single:last-child .icon-process-single__line {
  display: none;
}

.icon-process-single__content-wrap {
  position: relative;
  text-align: center;
  margin: 0 auto;
  max-width: 320px;
}

.icon-process-single__media {
  position: relative;
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 40px;
  font-size: 34px;
  background: #ffffff;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  margin: 0 auto 52px;
  color: #38cb89;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .icon-process-single__media {
    margin-bottom: 30px;
  }
}

.icon-process-single__media i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-process-single__title {
  color: #6d70a6;
  margin-bottom: 14px;
}

.icon-process-single:hover .icon-process-single__media {
  background-color: #38cb89;
  color: #ffffff;
}

.gradation-title-wrapper {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .gradation-title-wrapper {
    flex-direction: column;
  }
}

.gradation-sub-heading .heading {
  position: relative;
  font-size: 13px;
  line-height: 2em;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  padding: 35px 80px 35px 42px;
  z-index: 1;
}

.gradation-sub-heading .heading::before {
  background-color: #086ad8;
  content: '';
  position: absolute;
  top: 47px;
  left: 0;
  width: 34px;
  height: 1px;
}

.gradation-sub-heading .heading mark {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 120px;
  line-height: .8;
  font-weight: 900;
  color: #ededed;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .gradation-sub-heading .heading {
    margin-top: 30px;
  }
}

.ht-gradation.style-01 {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.ht-gradation.style-01 .item {
  flex-grow: 1;
  position: relative;
  padding: 0 15px;
}

.ht-gradation.style-01 .item .line {
  width: 100%;
  height: 1px;
  background: #ededed;
  position: absolute;
  top: 24px;
  left: 15px;
}

.ht-gradation.style-01 .item:last-child .line {
  display: none;
}

.ht-gradation.style-01 .item .mask {
  opacity: 0;
  visibility: hidden;
}

.ht-gradation.style-01 .item:hover .mask {
  opacity: 1;
  visibility: visible;
}

.ht-gradation.style-01 .item:hover .circle {
  color: #ffffff;
  background: #086AD8;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .ht-gradation.style-01 {
    display: block;
  }
  .ht-gradation.style-01 .item {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  .ht-gradation.style-01 .item .line {
    left: 39px;
    height: 100%;
    width: 1px;
    top: 15px;
  }
  .ht-gradation.style-01 .content-wrap {
    padding: 10px 0 50px 30px;
  }
}

.ht-gradation.style-01 .circle-wrap {
  display: inline-block;
  position: relative;
  margin-bottom: 56px;
}

.ht-gradation.style-01 .circle {
  position: relative;
  z-index: 1;
  width: 48px;
  height: 48px;
  line-height: 44px;
  text-align: center;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  font-size: 18px;
  background-color: #fff;
  margin-bottom: 0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-gradation.style-01 .wave-pulse {
  width: 1px;
  height: 0;
  margin: 0 auto;
}

.ht-gradation.style-01 .wave-pulse::before,
.ht-gradation.style-01 .wave-pulse::after {
  opacity: 0;
  content: '';
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ababab;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: gradationMask 3s linear infinite;
  animation: gradationMask 3s linear infinite;
}

.ht-gradation.style-01 .wave-pulse-2::before {
  animation-delay: 1.2s;
}

.ht-gradation.style-01 .wave-pulse-3::before {
  animation-delay: 2.4s;
}

.ht-gradation.style-01 .heading {
  font-weight: 500;
}

.ht-gradation.style-01 .text {
  margin-top: 17px;
}

.ht-gradation.style-01 .gradation-btn {
  font-weight: 500;
  margin-top: 20px;
  font-size: 14px;
  color: #086AD8;
}

.ht-gradation.style-01 .gradation-btn:hover {
  color: #d2a98e;
}

@-webkit-keyframes gradationMask {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-color: transparent;
  }
}

@keyframes gradationMask {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-color: transparent;
  }
}

/*=====  End of icon process  ======*/
/*=============================================
=        19. Instagram Css            =
=============================================*/
.instagram-feed-thumb {
  overflow: hidden;
  margin: -10px;
}

.instagram-image {
  width: 20%;
  overflow: hidden;
  position: relative;
  transition: all 0.4s ease-in-out;
}

.instagram-image::after {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.instagram-image::before {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #000;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  content: '\f004';
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 3;
  text-align: center;
  line-height: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-image {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-image {
    width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-image {
    width: 33.333%;
  }
}

@media only screen and (max-width: 575px) {
  .instagram-image {
    width: 50%;
  }
}

.instagram-image img {
  width: 100%;
}

.instagram-image:hover::after {
  opacity: 1;
  visibility: visible;
}

.instagram-image:hover::before {
  opacity: 1;
  visibility: visible;
}

.instagram-image:hover img {
  transform: scale(1.1);
}

/*========================================
=        20. Message Box Css            =
==========================================*/
.ht-message-box {
  background: #eee;
  padding: 27px 30px 27px 50px;
  font-weight: 500;
  border-radius: 5px;
}

.ht-message-box .icon {
  margin-right: 10px;
}

.ht-message-box.style-info {
  color: #5b63fe;
  background: rgba(91, 99, 254, 0.1);
}

.ht-message-box.style-success {
  background: rgba(56, 203, 137, 0.1);
  color: #38cb89;
}

.ht-message-box.style-error {
  background: rgba(254, 40, 84, 0.1);
  color: #fe2854;
}

.ht-message-box.style-warning {
  color: #fb0;
  background: rgba(255, 187, 0, 0.1);
}

/*========================================
=        21. Video Popup Css            =
==========================================*/
.single-popup-wrap {
  position: relative;
}

.video-link {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
}

.video-link .ht-popup-video.video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(8, 106, 216, 0.8);
}

.video-link .ht-popup-video.video-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.video-link .ht-popup-video.video-button .video-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%, -50%);
  pointer-events: none;
}

.video-link .ht-popup-video.video-button .video-mark .wave-pulse {
  width: 1px;
  height: 0;
  margin: 0 auto;
}

.video-link .ht-popup-video.video-button .video-mark .wave-pulse::after, .video-link .ht-popup-video.video-button .video-mark .wave-pulse::before {
  opacity: 0;
  content: '';
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 3px solid #086AD8;
  animation: zoomBig 3.25s linear infinite;
  animation-delay: 0s;
}

.video-link .ht-popup-video.video-button .video-mark .wave-pulse::before {
  animation-delay: .75s;
}

.video-link .ht-popup-video .video-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.video-link .ht-popup-video .video-button__one {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video .video-button__one .video-play {
  width: 72px;
  height: 72px;
  background: transparent;
  border: 6px solid #fff;
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video .video-button__one .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video .video-button__one .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
}

.video-link .ht-popup-video .video-button__two {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video .video-button__two .video-play {
  width: 78px;
  height: 78px;
  background: #086AD8;
  box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

.video-link .ht-popup-video .video-button__two .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video .video-button__two .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #ffffff;
}

.video-link:hover .video-play {
  transform: scale3d(1.15, 1.15, 1.15);
}

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

.center-plexible-row .swiper-slide {
  width: auto;
}

.center-plexible-row .single-flexible-slider img {
  height: 400px;
  /*width: auto;*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .center-plexible-row .single-flexible-slider img {
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .center-plexible-row .single-flexible-slider img {
    height: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .center-plexible-row .single-flexible-slider img {
    height: 100%;
  }
}

.auto-plexible-row .swiper-slide {
  width: auto;
}

.auto-plexible-row .single-flexible-slider img {
  height: 400px;
  /*width: auto;*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .auto-plexible-row .single-flexible-slider img {
    height: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .auto-plexible-row .single-flexible-slider img {
    height: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .auto-plexible-row .single-flexible-slider img {
    height: 100%;
  }
}

/*=============================================
=            22. Pricing table Css           =
==============================================*/
.pricing-table-wrapper {
  margin-bottom: -30px;
}

.pricing-table-one {
  align-items: center;
}

.pricing-table-one .pricing-table {
  margin-bottom: 30px;
}

.pricing-table-one .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 49px 20px 51px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
}

.pricing-table-one .pricing-table__header {
  margin-bottom: 25px;
}

.pricing-table-one .pricing-table__header .sub-title {
  font-size: 15px;
  line-height: 1.5;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 21px;
  z-index: 2;
  position: relative;
}

.pricing-table-one .pricing-table__image {
  position: relative;
  width: 162px;
  height: 162px;
  border-radius: 50%;
  margin: 0 auto 20px;
  background-image: -webkit-linear-gradient(290deg, #fbf7f4 0%, #fefdfd 100%);
  background-image: linear-gradient(160deg, #fbf7f4 0%, #fefdfd 100%);
  align-items: center;
  display: flex;
  justify-content: center;
}

.pricing-table-one .pricing-table__title {
  color: #ccc;
  margin-bottom: 19px;
  font-size: 15px;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
}

.pricing-table-one .pricing-table__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: 500;
}

.pricing-table-one .pricing-table__price-wrap .currency {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
}

.pricing-table-one .pricing-table__price-wrap .price {
  font-size: 56px;
  line-height: .8;
  margin: 0 4px;
  font-weight: 300;
  color: #333;
}

.pricing-table-one .pricing-table__price-wrap .period {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
  align-self: flex-end;
}

.pricing-table-one .pricing-table__list {
  margin: 29px auto 0;
  max-width: 270px;
}

.pricing-table-one .pricing-table__list li {
  position: relative;
  display: block;
  padding: 7px 0 7px 31px;
}

.pricing-table-one .pricing-table__list li::before {
  content: '\f00c';
  color: #d2a98e;
  position: absolute;
  top: 12px;
  left: 0;
  display: inline-block;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.pricing-table-one .pricing-table__list li .featured {
  font-weight: 500;
}

.pricing-table-one .pricing-table__footer {
  margin-top: 30px;
  padding-top: 8px;
}

.pricing-table-one .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-one .pricing-table__footer .ht-btn--outline {
  border-width: 1px;
}

.pricing-table-one .pricing-table__feature-mark {
  position: absolute;
  top: 0;
  right: 0;
  height: 92px;
  width: 92px;
  background: #d2a98e;
}

.pricing-table-one .pricing-table__feature-mark::after {
  transform: rotate(-45deg);
  background: none repeat scroll 0 0 #fff;
  content: "";
  height: 159px;
  right: 23px;
  position: absolute;
  top: 10px;
  width: 100%;
}

.pricing-table-one .pricing-table__feature-mark span {
  position: absolute;
  top: 20px;
  right: 7px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.19;
  display: block;
  color: #fff;
  transform: rotate(45deg);
  width: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pricing-table-one .pricing-table:hover .pricing-table__inner {
  box-shadow: 0 41px 43px rgba(51, 51, 51, 0.07);
  border: 0 solid transparent;
  transform: translateY(-5px);
}

.pricing-table-one .pricing-table--popular .pricing-table__inner {
  border: 0 solid transparent;
  padding: 49px 20px 51px;
}

.pricing-table-two .pricing-table {
  margin-bottom: 30px;
}

.pricing-table-two .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 34px 20px 40px;
  border: 1px solid #dce6ed;
  border-radius: 5px;
  background-color: #ffffff;
}

.pricing-table-two .pricing-table__header {
  margin-bottom: 25px;
}

.pricing-table-two .pricing-table__title {
  color: #333;
  margin-bottom: 24px;
}

.pricing-table-two .pricing-table__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.pricing-table-two .pricing-table__price-wrap .currency {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
}

.pricing-table-two .pricing-table__price-wrap .price {
  font-size: 56px;
  line-height: .8;
  margin: 0 4px;
  font-weight: 300;
  color: #333;
}

.pricing-table-two .pricing-table__price-wrap .period {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
  align-self: flex-end;
}

.pricing-table-two .pricing-table__list li {
  position: relative;
  display: block;
  padding: 5px 0;
}

.pricing-table-two .pricing-table__list li .featured {
  font-weight: 500;
}

.pricing-table-two .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-two .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-two .pricing-table__footer .ht-btn:hover {
  background: #086AD8;
}

.pricing-table-two .pricing-table__feature-mark {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 0;
  height: 0;
  border-top: 88px solid #d2a98e;
  border-bottom: 88px solid transparent;
  border-left: 88px solid transparent;
}

.pricing-table-two .pricing-table__feature-mark span {
  position: absolute;
  top: -72px;
  right: 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.19;
  display: block;
  color: #fff;
  transform: rotate(45deg);
}

.pricing-table-two .pricing-table:hover .pricing-table__inner {
  box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
  border: 0 solid transparent;
  padding: 35px 21px 41px;
  transform: translateY(-5px);
}

.pricing-table-two .pricing-table--popular .pricing-table__inner {
  box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
  border: 0 solid transparent;
  padding: 35px 21px 41px;
  background: #002FA6;
}

.pricing-table-two .pricing-table--popular .pricing-table__title {
  color: #ffffff;
}

.pricing-table-two .pricing-table--popular .pricing-table__price-wrap .currency {
  color: #ffffff;
}

.pricing-table-two .pricing-table--popular .pricing-table__price-wrap .price {
  color: #ffffff;
}

.pricing-table-two .pricing-table--popular .pricing-table__price-wrap .period {
  color: #ffffff;
}

.pricing-table-two .pricing-table--popular .pricing-table__list li {
  color: #ffffff;
}

.ht-plans-menu .tab__item:first-child a {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.ht-plans-menu .tab__item:last-child a {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ht-plans-menu .tab__item a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  border: 1px solid #ededed;
  background: #fff;
  color: #454545;
  padding: 18px 10px 16px;
  min-width: 160px;
  border-radius: 0;
}

.ht-plans-menu .tab__item a.active {
  background: #086AD8;
  color: #ffffff;
  border-color: #086AD8 !important;
}

/*=====  End of pricing table  ======*/
/*=============================================
=            23. progress Bar Css           =
==============================================*/
.progress-bar--one .progress-charts {
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

.progress-bar--two .progress-charts {
  padding-bottom: 20px;
  overflow: hidden;
}

.progress-charts .heading {
  color: #6d70a6;
  margin-bottom: 12px;
  font-weight: 400;
}

.progress-charts .progress {
  height: 4px;
  overflow: visible;
  font-size: 14px;
  background-color: #f5f5f5;
  border-radius: 0;
}

.progress-charts .progress .progress-bar {
  position: relative;
  background: #086AD8;
  color: #086AD8;
}

.progress-charts .progress .progress-bar::after, .progress-charts .progress .progress-bar::before {
  content: '';
  position: absolute;
  right: -4px;
  top: 50%;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.progress-charts .progress .progress-bar::after {
  height: 13px;
  width: 13px;
  border: 3px solid currentColor;
}

.progress-charts .progress .progress-bar::before {
  height: 28px;
  width: 28px;
  border: 1px solid currentColor;
  opacity: .3;
}

.progress-charts .progress .progress-bar span.percent-label {
  position: absolute;
  right: -45px;
  font-size: 14px;
  font-weight: 500;
  top: -40px;
  color: #2e3280;
  line-height: 2;
}

.progress-charts .progress .progress-bar.gradient-1 {
  color: #61fded;
  background-color: #0d8abc;
  background-image: linear-gradient(-224deg, #0d8abc 0px, #61fded 100%);
}

.progress-charts .progress .progress-bar.gradient-2 {
  color: #eece90;
  background-color: #d45529;
  background-image: linear-gradient(-224deg, #d45529 0px, #eece90 100%);
}

.progress-charts .progress .progress-bar.gradient-3 {
  color: #5c51ff;
  background-color: #f646a9;
  background-image: linear-gradient(-224deg, #f646a9 0px, #5c51ff 100%);
}

.progress-charts .progress .progress-bar.gradient-4 {
  color: #e5529a;
  background-color: #e77654;
  background-image: linear-gradient(-224deg, #e77654 0px, #e5529a 100%);
}

.progress-charts .progress__two .progress {
  height: 16px;
  overflow: visible;
  font-size: 14px;
  border-radius: 0;
  height: 32px;
  padding: 6px;
  border-radius: 30px;
  box-shadow: none;
  background-color: #f2f2f2;
}

.progress-charts .progress__two .progress-bar {
  background-color: #9C7AF2;
  background: #086AD8;
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-radius: inherit;
}

.progress-charts .progress__two .progress-bar::after, .progress-charts .progress__two .progress-bar::before {
  display: none;
}

.progress-charts .progress__two .progress-bar span.percent-label {
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.progress-charts .progress__two .progress.gradient-1 {
  color: #61fded;
  background-color: #0d8abc;
  background-image: linear-gradient(-224deg, #0d8abc 0px, #61fded 100%);
}

.progress-charts .progress__two .progress.gradient-2 {
  color: #eece90;
  background-color: #d45529;
  background-image: linear-gradient(-224deg, #d45529 0px, #eece90 100%);
}

.progress-charts .progress__two .progress.gradient-3 {
  color: #5c51ff;
  background-color: #f646a9;
  background-image: linear-gradient(-224deg, #f646a9 0px, #5c51ff 100%);
}

.progress-charts .progress__two .progress.gradient-4 {
  color: #e5529a;
  background-color: #e77654;
  background-image: linear-gradient(-224deg, #e77654 0px, #e5529a 100%);
}

/*=============================================
=        25. Row Separators Css            =
=============================================*/
.row-separators-wrap {
  position: relative;
}

.separators-space {
  padding-top: 300px;
  padding-bottom: 300px;
}

.row-separators-images {
  background-image: url(../images/bg/home-resolutions-hero-bg-image.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.row-separators-bg {
  background: #69afed;
}

.vc_row-separator.bottom {
  bottom: 0;
}

.vc_row-separator svg {
  height: 100px;
  width: 100%;
}

.vc_row-separator {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  line-height: 0;
}

.row-separators-style .vc_row-separator svg {
  fill: #fff;
}

.square-style .vc_row-separator.square.bottom {
  transform: translate(-50%, 50%);
}

.square-style .vc_row-separator.square {
  width: auto;
  left: 50%;
}

.square-style .vc_row-separator svg {
  height: 90px;
}

.square-style .vc_row-separator svg {
  fill: #f7be68;
}

.big-triangle-style .big_triangle.bottom {
  transform: scaleY(-1);
}

.big-triangle-style .vc_row-separator svg {
  fill: #fff;
}

.tilt-right-style .tilt_right.bottom {
  transform: scale(-1, 1);
}

.tilt-right-style .vc_row-separator svg {
  fill: #69afed;
}

.tilt-left-style .vc_row-separator.top {
  top: 0;
}

.tilt-left-style .vc_row-separator.tilt_left.top {
  transform: scale(1, -1);
}

.tilt-left-style .vc_row-separator svg {
  height: 100px;
}

.tilt-left-style .vc_row-separator svg {
  fill: #69afed;
}

.wavify-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.wavify-wrapper svg {
  height: 380px;
}

.triangle-bottom .vc_row-separator.triangle.bottom {
  transform: scaleY(-1);
}

.triangle-bottom .vc_row-separator.triangle.bottom {
  transform: scaleY(-1);
}

.triangle-bottom .vc_row-separator svg {
  fill: #fff;
}

.triangle-bottom .vc_row-separator svg {
  height: 40px;
}

.curve-style .vc_row-separator.bottom {
  bottom: 0;
}

.curve-style .vc_row-separator svg {
  fill: #fff;
}

.curve-style .vc_row-separator svg {
  height: 50px;
}

.waves-style .vc_row-separator.bottom {
  bottom: 0;
}

.waves-style .vc_row-separator svg {
  height: 30px;
}

.waves-style .vc_row-separator svg {
  fill: #69afed;
}

.circle-style .vc_row-separator.top {
  top: 0;
}

.circle-style .vc_row-separator svg {
  height: 50px;
}

.circle-style .vc_row-separator svg {
  fill: #69afed;
}

.default-bg-2 {
  background: #69AFED;
}

.particles-area {
  position: relative;
}

.constellation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.vc_row-separator.bottom {
  bottom: 0;
}

.triangle-one-style .vc_row-separator svg, .triangle-two--style .vc_row-separator svg {
  fill: #69afed;
}

.triangle-one-style .vc_row-separator svg, .triangle-two--style .vc_row-separator svg {
  height: 200px;
}

.triangle-two--style .vc_row-separator.tilt_right.top {
  transform: scale(-1, -1);
}

/*=============================================
=          24. Circle Progress Css            =
=============================================*/
.circle-progress-wrapper {
  position: relative;
}

.feature-list-progress {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 575px), only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-list-progress {
    flex-direction: column;
    align-items: center;
  }
}

.feature-list-progress__info {
  margin-left: 40px;
  flex-basis: calc(100% - 170px);
}

@media only screen and (max-width: 575px), only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-list-progress__info {
    margin-top: 20px;
    margin-left: 0;
  }
}

.feature-list-progress__counter {
  flex-basis: 170px;
}

.feature-list-progress__list li {
  margin-bottom: 10px;
}

.chart-progress {
  position: relative;
  width: 160px;
  height: 160px;
}

.chart-progress canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chart-progress.cart-pogress-small {
  width: 140px;
  height: 140px;
}

.chart-progress__inner-wrapper {
  width: 100%;
  height: 100%;
  padding: 13px;
  position: relative;
}

.chart-progress__inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #086AD8;
}

.chart-progress__inner--gradient {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f76b1c;
  background-image: linear-gradient(-224deg, #fad961 0, #f76b1c 100%);
}

.chart-progress__inner--bg-blue {
  background: #5945e6;
}

.chart-progress__inner--theme-two {
  background: #d2a98e;
}

.chart-progress__inner--white {
  background: transparent;
}

.chart-progress__inner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart-progress__inner-text {
  color: #ffffff;
}

.chart-progress__inner-text.text-black {
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}

.chart-progress__inner-text.chart-icon {
  font-size: 34px;
}

.circle-design {
  position: absolute;
  border-radius: 50%;
  animation: jump infinite 2s;
  animation-direction: alternate;
  background-color: #f76b1c;
  background: #086AD8;
}

.circle-design.one {
  width: 43px;
  height: 43px;
  left: 0;
  bottom: 28px;
}

.circle-design.two {
  width: 17px;
  height: 17px;
  right: -20px;
  top: 50%;
  margin-top: -8px;
  animation-delay: 1s;
}

.circle-design--theme-two {
  background: #d2a98e;
}

.circle-design--gradient {
  background-color: #f76b1c;
  background-image: linear-gradient(-224deg, #fad961 0, #f76b1c 100%);
}

.circle-design--bg-green {
  background: #38cb89;
  box-shadow: 0 2px 17px rgba(89, 69, 230, 0.27);
}

@keyframes jump {
  0% {
    transform: translateY(-10px) scale(0.9, 0.9);
  }
  100% {
    transform: translateY(10px) scale(1, 1);
  }
}

.feature-list-progress {
  z-index: 3;
  position: relative;
}

/*=====  End of circle progress  ======*/
/*=============================================
=        26. Social Networks Css            =
=============================================*/
.ht-social-networks .item {
  display: inline-block;
}

.ht-social-networks .item .social-link {
  display: block;
  padding: 5px 8px;
}

.ht-social-networks.large-icon .social-link {
  padding: 5px 13px;
  font-size: 20px;
}

.ht-social-networks.extra-large-icon .social-link {
  padding: 5px 15px;
  font-size: 30px;
}

.ht-social-networks.flat-round .item, .ht-social-networks.solid-rounded-icon .item {
  margin: 8px;
}

.ht-social-networks.flat-round .social-link, .ht-social-networks.solid-rounded-icon .social-link {
  font-size: 18px;
  display: block;
  text-align: center;
  height: 48px;
  width: 48px;
  line-height: 40px;
  background: #ededed;
  color: #696969;
  border-radius: 50%;
  position: relative;
}

.ht-social-networks.flat-round .social-link:hover, .ht-social-networks.solid-rounded-icon .social-link:hover {
  color: #ffffff;
  background: #086AD8;
}

.ht-social-networks.solid-rounded-icon .social-link {
  background: transparent;
  border: 1px solid #e0e0e0;
}

.ht-social-networks.solid-rounded-icon .social-link:hover {
  border: 1px solid #086AD8;
  color: #ffffff;
}

.ht-social-networks.solid-rounded-icon.social-white .social-link {
  color: #ffffff;
}

.ht-social-networks.solid-rounded-icon.social-white .social-link:hover {
  border: 1px solid #ffffff;
  color: #086AD8;
  background: #ffffff;
}

.ht-social-networks.icon-tilte .link-icon {
  font-size: 16px;
  min-width: 16px;
  margin-right: 10px;
}

.ht-social-networks.tilte-style .social-link {
  position: relative;
}

.ht-social-networks.tilte-style .social-link::before {
  content: '-';
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
  color: #333;
}

.ht-social-networks.tilte-style .social-link:hover .link-text::after {
  background-color: #d2a98e;
  width: 100%;
  left: 0;
}

.ht-social-networks.tilte-style .item:first-child .social-link::before {
  display: none;
}

.ht-social-networks.tilte-style .link-text {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: #333;
  position: relative;
  padding: 5px 0;
  z-index: 1;
}

.ht-social-networks.tilte-style .link-text::before {
  content: '';
  height: 2px;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -2;
  background: rgba(0, 0, 0, 0.2);
}

.ht-social-networks.tilte-style .link-text::after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
}

.ht-social-networks.white-tilte-social .social-link::before {
  color: rgba(255, 255, 255, 0.7);
}

.ht-social-networks.white-tilte-social .social-link:hover .link-text::after {
  background-color: #ffffff;
}

.ht-social-networks.white-tilte-social .link-text {
  color: #fff;
}

.ht-social-networks.white-tilte-social .link-text::before {
  background: rgba(255, 255, 255, 0.7);
}

/*=============================================
=               28. Tabs Css                =
=============================================*/
.ht-tab-menu {
  margin: 0 -10px;
}

.ht-tab-menu li {
  margin: 0 10px;
}

.ht-tab-menu li a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #ededed !important;
  border: #ededed;
  background: #fff;
  color: #696969;
  padding: 21px 20px 19px;
  min-width: 200px;
  border-radius: 5px !important;
}

.ht-tab-menu li a.active {
  color: #086AD8;
  border-color: #086AD8 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-tab-menu li a {
    padding: 21px 10px 19px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-tab-menu li a {
    padding: 12px 13px 12px;
    min-width: 150px;
    margin-bottom: 10px;
  }
}

.ht-tab-menu_two li {
  flex-grow: 1;
  flex-basis: 0;
}

.tab-history-image img {
  border-radius: 5px;
}

.tab-content-inner .text {
  font-size: 18px;
  font-size: 18px;
}

.tab-slider-nav--list {
  display: block;
  margin-bottom: 50px;
  opacity: 0.6;
}

.tab-slider-nav--list:hover {
  opacity: 0.9;
}

.tab-slider-nav--list .ht-slider-tab-icon {
  background: rgba(255, 255, 255, 0.2);
  display: inline-block;
  float: left;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 32px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  margin-right: 30px;
}

.tab-slider-nav--list h5 {
  display: block;
  color: #fff;
  max-width: 280px;
}

.tab-slider-nav--list p {
  margin-top: 26px;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-slider-nav--list:last-child {
    margin-bottom: 0;
  }
}

.tab-slider-nav .slick-current .tab-slider-nav--list {
  opacity: 1;
}

.tab-slider-nav .slick-current .tab-slider-nav--list .ht-slider-tab-icon {
  background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%);
}

.start-ups-left-tab .tab-slider-nav--list {
  display: block;
  margin-bottom: 50px;
  opacity: 0.6 !important;
}

.start-ups-left-tab .tab-slider-nav--list:hover {
  opacity: 0.9;
}

.start-ups-left-tab .tab-slider-nav .slick-current .tab-slider-nav--list {
  opacity: 1 !important;
}

.start-ups-left-tab .tab-slider-nav .slick-current .tab-slider-nav--list .ht-slider-tab-icon {
  background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%) !important;
}

.tab-slider-images .slick-dots {
  text-align: center;
  margin-top: 30px;
}

.tab-slider-images .slick-dots li {
  display: inline-block;
  margin: 0 15px;
}

.tab-slider-images .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 8px;
  height: 8px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tab-slider-images .slick-dots li button:hover {
  width: 10px;
  height: 10px;
}

.tab-slider-images .slick-dots li.slick-active button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 9px #ededed;
}

.modern-grid--6 {
  display: grid;
  grid-row-gap: 60px;
  grid-template-columns: repeat(6, 1fr);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-grid--6 {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-grid--6 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .modern-grid--6 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.carousel-slider__content {
  margin-top: 25px;
  padding: 0 20px;
}

.carousel-slider__content .headeing {
  margin-bottom: 10px;
}

/*=============================================
=             29. Team Member Css             =
==============================================*/
.sider-title-button-box .ht-btn {
  margin-right: 15px;
}

.ht-team-member .team-image {
  position: relative;
}

.ht-team-member .social-networks {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 6px 0;
  background-color: #002FA6;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-team-member .social-networks a {
  display: inline-block;
  font-size: 16px;
  line-height: 0;
  color: #fff;
  padding: 10px 14px;
}

.ht-team-member:hover .social-networks {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.ht-team-member .team-info {
  margin-top: 20px;
}

.ht-team-member-style-one .ht-team-member {
  max-width: 310px;
  margin: auto;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .ht-team-member-style-one .ht-team-member {
    margin-bottom: 30px;
  }
}

.ht-team-member-style-two .ht-team-member {
  max-width: 350px;
  margin: auto;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .ht-team-member-style-two .ht-team-member {
    margin-bottom: 30px;
  }
}

.ht-team-member-style-three .ht-team-member {
  max-width: 350px;
  margin: auto;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .ht-team-member-style-three .ht-team-member {
    margin-bottom: 30px;
  }
}

.team_member-list .team__title {
  font-size: 15px;
  text-transform: uppercase;
}

/*----------------------------
    tooltip library
----------------------------*/
[class*="hint--"] {
  position: relative;
  display: inline-block;
  /**
	 * tooltip arrow
	 */
  /**
	 * tooltip body
	 */
}

[class*="hint--"]:before, [class*="hint--"]:after {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
}

[class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
  visibility: visible;
  opacity: 1;
}

[class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms;
}

[class*="hint--"]:before {
  content: '';
  position: absolute;
  background: transparent;
  border: 7px solid transparent;
  z-index: 1000001;
}

[class*="hint--"]:after {
  background: #086AD8;
  color: white;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

[class*="hint--"][aria-label]:after {
  content: attr(aria-label);
}

[class*="hint--"][data-hint]:after {
  content: attr(data-hint);
}

[aria-label='']:before, [aria-label='']:after,
[data-hint='']:before,
[data-hint='']:after {
  display: none !important;
}

/**
 * source: hint-position.scss
 *
 * Defines the positoning logic for the tooltips.
 *
 * Classes added:
 * 	1) hint--top
 * 	2) hint--bottom
 * 	3) hint--left
 * 	4) hint--right
 */
/**
 * set default color for tooltip arrows
 */
.hint--top-left:before {
  border-top-color: #086AD8;
}

.hint--top-right:before {
  border-top-color: #086AD8;
}

.hint--top:before {
  border-top-color: #086AD8;
}

.hint--bottom-left:before {
  border-bottom-color: #086AD8;
}

.hint--bottom-right:before {
  border-bottom-color: #086AD8;
}

.hint--bottom:before {
  border-bottom-color: #086AD8;
}

.hint--left:before {
  border-left-color: #086AD8;
}

.hint--right:before {
  border-right-color: #086AD8;
}

.hint--white:after {
  background: #ffffff;
  color: #333;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
}

.hint--white:before {
  border-top-color: #ffffff;
}

.hint--black:after {
  background: #000000;
  color: #ffffff;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
}

.hint--black:before {
  border-top-color: #000000;
}

.hint--black.hint--bottom::before {
  border-bottom-color: #000000;
}

.hint--black.hint--top-left::before {
  border-top-color: #000000;
}

.hint--black.hint--bottom-left::before {
  border-bottom-color: #000000;
}

.hint--black.hint--top::before {
  border-top-color: #000000;
}

.hint--black.hint--left::before {
  border-left-color: #000000;
}

.hint--black:before {
  border-top-color: transparent;
}

.hint--theme-two:after {
  background: #d2a98e;
  color: #ffffff;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
}

.hint--theme-two:before {
  border-top-color: #d2a98e;
}

.hint--theme-two.hint--bottom::before {
  border-bottom-color: #d2a98e;
}

.hint--theme-two.hint--top-left::before {
  border-top-color: #d2a98e;
}

.hint--theme-two.hint--top::before {
  border-top-color: #d2a98e;
}

.hint--theme-two.hint--left::before {
  border-left-color: #d2a98e;
}

.hint--theme-two:before {
  border-top-color: transparent;
}

/**
 * top tooltip
 */
.hint--top:before {
  margin-bottom: -13px;
}

.hint--top:before, .hint--top:after {
  bottom: 100%;
  left: 50%;
}

.hint--top:before {
  left: calc(50% - 7px);
}

.hint--top:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hint--top:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top:hover:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}

/**
 * bottom tooltip
 */
.hint--bottom:before {
  margin-top: -13px;
}

.hint--bottom:before, .hint--bottom:after {
  top: 100%;
  left: 50%;
}

.hint--bottom:before {
  left: calc(50% - 7px);
}

.hint--bottom:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hint--bottom:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom:hover:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}

/**
 * right tooltip
 */
.hint--right:before {
  margin-left: -13px;
  margin-bottom: -7px;
}

.hint--right:after {
  margin-bottom: -15px;
}

.hint--right:before, .hint--right:after {
  left: 100%;
  bottom: 50%;
}

.hint--right:hover:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

.hint--right:hover:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

/**
 * left tooltip
 */
.hint--left:before {
  margin-right: -13px;
  margin-bottom: -7px;
}

.hint--left:after {
  margin-bottom: -15px;
}

.hint--left:before, .hint--left:after {
  right: 100%;
  bottom: 50%;
}

.hint--left:hover:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

.hint--left:hover:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

/**
 * top-left tooltip
 */
.hint--top-left:before {
  margin-bottom: -13px;
}

.hint--top-left:before, .hint--top-left:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-left:before {
  left: calc(50% - 7px);
}

.hint--top-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.hint--top-left:after {
  margin-left: 14px;
}

.hint--top-left:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}

/**
 * top-right tooltip
 */
.hint--top-right:before {
  margin-bottom: -13px;
}

.hint--top-right:before, .hint--top-right:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-right:before {
  left: calc(50% - 7px);
}

.hint--top-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.hint--top-right:after {
  margin-left: -14px;
}

.hint--top-right:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top-right:hover:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

/**
 * bottom-left tooltip
 */
.hint--bottom-left:before {
  margin-top: -13px;
}

.hint--bottom-left:before, .hint--bottom-left:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-left:before {
  left: calc(50% - 7px);
}

.hint--bottom-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.hint--bottom-left:after {
  margin-left: 14px;
}

.hint--bottom-left:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}

/**
 * bottom-right tooltip
 */
.hint--bottom-right:before {
  margin-top: -13px;
}

.hint--bottom-right:before, .hint--bottom-right:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-right:before {
  left: calc(50% - 7px);
}

.hint--bottom-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.hint--bottom-right:after {
  margin-left: -14px;
}

.hint--bottom-right:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom-right:hover:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--no-animate:before, .hint--no-animate:after {
  -webkit-transition-duration: 0ms;
  -moz-transition-duration: 0ms;
  transition-duration: 0ms;
}

.hint--bounce:before, .hint--bounce:after {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, visibility 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}

/*=============================================
=           30. Timeline Css           =
=============================================*/
.ht-timeline.style-01 .col-md-6 {
  padding-left: 30px;
  padding-right: 30px;
}

.ht-timeline.style-01 .tm-timeline-list {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 32px 0 65px;
}

.ht-timeline.style-01 .item {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .item {
    padding-left: 45px;
    width: 100%;
  }
}

.ht-timeline.style-01 .item + .item {
  margin-top: 68px;
}

.ht-timeline.style-01 .line {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  height: 100%;
  border-left: 2px solid #086ad8;
  opacity: .15;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .line {
    left: 15px;
  }
}

.ht-timeline.style-01 .dots {
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 30px;
  height: 30px;
  color: #086ad8;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .dots {
    right: auto;
    left: 0;
    transform: none;
  }
}

.ht-timeline.style-01 .dots::before {
  content: '';
  position: absolute;
  border: 1px solid currentColor;
  border-radius: 50%;
  top: 0;
  left: 0;
  opacity: .3;
  width: 30px;
  height: 30px;
}

.ht-timeline.style-01 .dots .middle-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  z-index: 2;
  border: 3px solid currentColor;
  background: #fff;
}

.ht-timeline.style-01 .timeline-col .inner {
  text-align: right;
  width: 500px;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .timeline-col .inner {
    text-align: left;
  }
}

.ht-timeline.style-01 .timeline-col .inner .year {
  color: #086AD8;
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 27px;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .timeline-col .inner .year {
    font-size: 32px;
  }
}

.ht-timeline.style-01 .timeline-col .inner .photo img {
  border-radius: 5px;
}

.ht-timeline.style-01 .timeline-info .inner {
  padding-top: 130px;
  float: right;
  width: 500px;
  max-width: 100%;
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-timeline.style-01 .timeline-info .inner {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .timeline-info .inner {
    padding-top: 30px;
  }
}

.ht-timeline.style-01 .timeline-info .content-body {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

.ht-timeline.style-01 .timeline-info .heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.ht-timeline.style-01 .timeline-info .text {
  font-size: 18px;
  line-height: 2;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .timeline-info .text {
    font-size: 16px;
  }
}

.ht-timeline.style-01 .item:nth-child(2n+1) .timeline-feature {
  order: 3;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .item:nth-child(2n+1) .timeline-feature {
    order: -1;
  }
}

.ht-timeline.style-01 .item:nth-child(2n+1) .timeline-info > .inner {
  float: left;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .item:nth-child(2n+1) .timeline-info > .inner {
    text-align: left;
  }
}

.ht-timeline.style-01 .item:nth-child(2n+1) .timeline-feature > .inner {
  text-align: left;
  float: right;
}

.ht-timeline.style-01 .item:nth-child(2n+1) .timeline-feature > .inner {
  text-align: left;
  float: right;
}

/*=============================================
=         31. Testimonial slider Css           =
=============================================*/
.testimonial-slider__container {
  padding-bottom: 0;
}

.testimonial-slider--info {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .testimonial-slider--info {
    flex-direction: column;
  }
}

.testimonial-slider__media {
  flex-shrink: 0;
  margin-right: 30px;
}

.testimonial-slider__media img {
  border-radius: 50%;
}

.testimonial-slider__text {
  font-size: 18px;
}

.testimonial-slider__author {
  flex-grow: 1;
}

.testimonial-slider__author .testimonial-rating {
  font-size: 16px;
  margin-bottom: 10px;
  color: #ffb944;
}

.testimonial-slider__author .author-info {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .testimonial-slider__author .author-info {
    flex-direction: column;
  }
}

.testimonial-slider__author .name {
  color: #333333;
}

.testimonial-slider__author .designation {
  font-size: 14px;
}

.testimonial-slider__author .designation:before {
  content: ' / ';
  padding: 0 5px;
}

.testimonial-slider__one {
  position: relative;
  background: #fff;
  padding: 31px 50px 47px;
  border-radius: 5px;
  margin: 20px 15px 35px;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.1);
  transition: all 0.3s ease-in-out;
  transform: translateY(0px) !important;
}

.testimonial-slider__one .testimonial-slider--info {
  display: flex;
  align-items: center;
}

.testimonial-slider__one .testimonial-slider--info .post-thumbnail {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .testimonial-slider__one .testimonial-slider--info .post-thumbnail {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.testimonial-slider__one:hover {
  transform: translateY(-5px) !important;
}

@media only screen and (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-slider__one {
    padding: 31px 20px 31px;
  }
}

.testimonial-slider__single {
  background: #ffffff;
  padding: 51px 37px 40px;
  border-radius: 5px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider__single .testimonial-subject {
  margin-bottom: 15px;
}

.testimonial-slider__single .testimonial-slider__text {
  color: #002FA6;
}

.testimonial-slider__single .author-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.testimonial-slider__container-two .swiper-slide.swiper-slide-prev {
  opacity: 0.5;
}

.testimonial-slider__container-two .swiper-slide.swiper-slide-next {
  opacity: 0.5;
}

.modern-it-company-testimonial {
  max-width: 570px;
}

.modern-it-company-testimonial .text {
  font-size: 34px;
  line-height: 1.34;
  font-weight: 500;
  color: #000000;
  margin-bottom: 80px;
}

.modern-it-company-testimonial .author h6 {
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-it-company-testimonial .text {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-it-company-testimonial {
    margin-top: 30px;
  }
  .modern-it-company-testimonial .text {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-it-company-testimonial-bg {
    background-position: left 144px bottom 340px !important;
  }
}

/*=====  End of testimonial slider  ======*/
/* =========================
    Typed Text 
=============================*/
.cd-headline.clip span {
  padding: 0;
}

.cd-headline.clip .cd-words-wrapper.brown-color::after {
  background-color: #ddd;
  width: 4px;
}

.cd-headline.clip span {
  padding: 0;
}

.cd-headline.clip .cd-words-wrapper.brown-color::after {
  background-color: #f10;
  width: 4px;
}

.typed-text-wrap {
  text-align: center;
}

.typed-text-wrap h4 {
  max-width: 700px;
  font-size: 48px;
  line-height: 1.34;
  margin-bottom: 0;
  word-wrap: break-word;
  font-weight: 700;
  margin-top: -20px;
}

.typed-text-wrap h4 span b {
  font-weight: 700;
  font-style: normal;
  color: #086AD8;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .typed-text-wrap h4 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .typed-text-wrap h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .typed-text-wrap h4 {
    font-size: 20px;
  }
}

/*===================================
=          32. Project Css           =
====================================*/
.projectinfotechno-bg {
  background-image: url(../images/patterns/mitech-home-infotechno-case-study-section-bg.png);
  background-repeat: no-repeat;
  background-position: bottom left;
}

.projects-slider__container .projects-wrap.style-01 {
  display: block;
}

.projects-slider__container .projects-wrap.style-01 .projects-image-box {
  margin: 20px 15px 35px;
}

.projects-wrap.style-01 {
  display: block;
}

.projects-wrap.style-01 .projects-image-box {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 18px 40px -10px rgba(51, 51, 51, 0.1);
  position: relative;
  margin-bottom: 30px;
  /*margin: 20px 15px 35px;*/
}

.projects-wrap.style-01 .content {
  padding: 25px 26px 94px;
  padding-bottom: 88px;
}

.projects-wrap.style-01 .content .heading {
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 2px;
  color: #333 !important;
}

.projects-wrap.style-01 .content .post-categories {
  color: #086AD8;
  margin-bottom: 2px;
}

.projects-wrap.style-01 .content .text {
  color: #696969;
  margin-top: 13px;
}

.projects-wrap.style-01 .content .box-projects-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 56px;
  line-height: 55px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #eee;
  color: #086AD8;
}

.projects-wrap.style-01 .content .box-projects-arrow::before {
  background-color: #086AD8;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.projects-wrap.style-01:hover {
  transform: translateY(-5px);
}

.projects-wrap.style-01:hover .content .heading {
  color: #086AD8;
}

.projects-wrap.style-01:hover .content .box-projects-arrow {
  color: #ffffff;
}

.projects-wrap.style-01:hover .content .box-projects-arrow::before {
  transform: translateY(0);
}

.projects-wrap.style-2 {
  margin-bottom: 30px;
  display: block;
}

.projects-wrap.style-2 .projects-image-box img {
  border-radius: 8px;
}

.projects-wrap.style-2 .content {
  padding: 22px 15px 24px;
  text-align: center;
}

.projects-wrap.style-2 .content .heading {
  font-weight: 600;
  line-height: 1.25;
  color: #333 !important;
}

.projects-wrap.style-2 .content .post-categories {
  color: #086AD8;
  margin-bottom: 5px;
}

.projects-wrap.style-2:hover {
  transform: translateY(-5px);
}

.projects-wrap.style-3 {
  display: block;
}

.projects-wrap.style-3 .projects-image-box {
  background: #fff;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}

.projects-wrap.style-3 .projects-image-box .projects-image {
  position: relative;
}

.projects-wrap.style-3 .projects-image-box .projects-image img {
  border-radius: 5px;
}

.projects-wrap.style-3 .projects-image-box .projects-image .project-logo {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.projects-wrap.style-3 .content {
  padding: 10px 0px 15px;
}

.projects-wrap.style-3 .content .heading {
  font-size: 20px;
  line-height: 1.5;
  margin: 0;
  color: #333 !important;
}

.projects-wrap.style-04 {
  display: block;
}

.projects-wrap.style-04 .projects-image-box {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 40px 5px rgba(51, 51, 51, 0.1);
  position: relative;
  padding: 98px 35px 8px;
  margin-bottom: 30px;
}

.projects-wrap.style-04 .projects-image-box .projects-image {
  position: relative;
  width: 162px;
  height: 162px;
  border-radius: 50%;
  margin: 0 auto 2px;
}

.projects-wrap.style-04 .projects-image-box .projects-image::before {
  background-image: -webkit-linear-gradient(290deg, #fbf7f4 0%, #fefdfd 100%);
  background-image: linear-gradient(160deg, #fbf7f4 0%, #fefdfd 100%);
  border-radius: 50%;
  -webkit-transform: translateY(-46px);
  -ms-transform: translateY(-46px);
  transform: translateY(-46px);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.projects-wrap.style-04 .projects-image-box .projects-image .image {
  z-index: 1;
  position: relative;
  text-align: center;
}

.projects-wrap.style-04 .content {
  padding: 0px 6px 94px;
  padding-bottom: 88px;
}

.projects-wrap.style-04 .content .heading {
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 2px;
  color: #333 !important;
}

.projects-wrap.style-04 .content .post-categories {
  color: #086AD8;
  margin-bottom: 2px;
}

.projects-wrap.style-04 .content .text {
  color: #696969;
  margin-top: 13px;
}

.projects-wrap.style-04 .content .box-projects-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 56px;
  line-height: 55px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #eee;
  color: #086AD8;
}

.projects-wrap.style-04 .content .box-projects-arrow::before {
  background-color: #086AD8;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.projects-wrap.style-04:hover {
  transform: translateY(-5px);
}

.projects-wrap.style-04:hover .content .heading {
  color: #086AD8;
}

.projects-wrap.style-04:hover .content .box-projects-arrow {
  color: #ffffff;
}

.projects-wrap.style-04:hover .content .box-projects-arrow::before {
  transform: translateY(0);
}

.messonry-button button {
  padding: 0;
  border: 0 none;
  position: relative;
  background: transparent;
}

.messonry-button button span {
  position: relative;
  display: inline-block;
  padding: 0 5px;
  margin: 5px 17px;
  position: relative;
  border-bottom: 2px solid transparent;
}

.messonry-button button span.filter-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.74;
  color: #086AD8;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.messonry-button button span.filter-counter {
  position: absolute;
  top: 0;
  left: 50%;
  visibility: hidden;
  margin: 0 auto;
  min-width: 34px;
  height: 24px;
  line-height: 24px;
  border-radius: 3px;
  font-size: 12px;
  background-color: #086AD8;
  color: #fff;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 600;
}

.messonry-button button span.filter-counter::before {
  position: absolute;
  right: 0;
  bottom: -6px;
  left: 0;
  display: block;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 4px solid #086AD8;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top-color: #086AD8;
}

.messonry-button button:hover span.filter-counter {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.messonry-button button:hover span.filter-text {
  color: #086AD8;
}

.messonry-button button.is-checked span {
  border-bottom: 2px solid #086AD8;
}

.messonry-button button.is-checked span.filter-text {
  color: #086AD8;
}

/*======================================
=         33. Contact Us Css            =
=======================================*/
.contact-form__two input[type="text"]:focus, .contact-form__two input[type="email"]:focus, .contact-form__two input[type="url"]:focus, .contact-form__two input[type="password"]:focus, .contact-form__two input[type="search"]:focus, .contact-form__two input[type="number"]:focus, .contact-form__two input[type="tel"]:focus, .contact-form__two input[type="range"]:focus, .contact-form__two input[type="date"]:focus, .contact-form__two input[type="month"]:focus, .contact-form__two input[type="week"]:focus, .contact-form__two input[type="time"]:focus, .contact-form__two input[type="datetime"]:focus, .contact-form__two input[type="datetime-local"]:focus, .contact-form__two input[type="color"]:focus, .contact-form__two textarea:focus, .contact-form__two select:focus, .contact-form__two select:focus, .contact-form__two textarea:focus {
  color: #222;
  border-color: transparent;
  background: #fff;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.contact-form__two select:focus {
  background: #fff url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.appointment-contact-bg {
  background: url(../images/bg/home-appointment-contact-bg-image.png);
  background-repeat: no-repeat;
  background-position: center right;
}

.service-contact-bg {
  background-color: #002fa6;
  background-image: url(../images/bg/mitech-home-services-contact-bg.png);
  background-repeat: no-repeat;
  background-position: left bottom;
}

.processing-contact-us-bg {
  background-color: #002fa6;
  background-image: url(../images/bg/mitech-processing-contact-bg.png);
  background-repeat: no-repeat;
  background-position: top right;
}

.contact-title .sub-title {
  font-size: 18px;
}

.contact-input {
  margin: 0 -10px;
}

.contact-input .contact-inner {
  float: left;
  margin: 0 0 20px;
  padding: 0 10px;
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .contact-input .contact-inner {
    width: 100%;
  }
}

.contact-inner {
  margin: 0 0 20px;
}

.contact-inner input, .contact-inner textarea {
  width: 100%;
  color: #000;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
}

.contact-inner textarea {
  height: 150px;
  padding: 15px;
}

.contact-inner select {
  color: #777;
}

.infotechno-contact-us-bg {
  background-color: #eff2f6;
  background-image: url(../images/bg/home-infotechno-contact-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .conact-us-wrap-one {
    margin-bottom: 30px;
  }
}

.conact-us-wrap-one .heading {
  position: relative;
  padding-left: 34px;
  font-weight: 600;
  line-height: 1.4;
}

.conact-us-wrap-one .heading::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 94%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #d2a98e;
}

.conact-us-wrap-one .sub-heading {
  margin-top: 15px;
  font-size: 18px;
  margin-left: 34px;
  color: #696969;
}

.conact-us-wrap-three {
  position: relative;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .conact-us-wrap-three {
    margin-bottom: 30px;
  }
}

.conact-us-wrap-three::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 94%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #d2a98e;
}

.conact-us-wrap-three .section-sub-title {
  padding-left: 34px;
}

.conact-us-wrap-three .heading {
  position: relative;
  padding-left: 34px;
  font-weight: 600;
  line-height: 1.4;
}

.conact-us-wrap-three .sub-heading {
  margin-top: 15px;
  font-size: 18px;
  margin-left: 34px;
  color: #696969;
}

.contact-info-one .icon, .contact-info-two .icon, .contact-info-three .icon {
  font-size: 40px;
  color: #086ad8;
}

.contact-info-one .heading, .contact-info-two .heading, .contact-info-three .heading {
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 10px;
}

.contact-info-one .call-us, .contact-info-two .call-us, .contact-info-three .call-us {
  line-height: 1.17;
  color: #002fa6;
  position: relative;
  display: inline-block;
}

.contact-info-one .call-us::before, .contact-info-two .call-us::before, .contact-info-three .call-us::before {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: 1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.contact-info-one .call-us:hover, .contact-info-two .call-us:hover, .contact-info-three .call-us:hover {
  color: #002fa6;
}

.contact-info-one .call-us:hover::before, .contact-info-two .call-us:hover::before, .contact-info-three .call-us:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}

.contact-info-one .call-us a:hover, .contact-info-two .call-us a:hover, .contact-info-three .call-us a:hover {
  color: #002fa6;
}

.contact-info-one .btn--secondary, .contact-info-two .btn--secondary, .contact-info-three .btn--secondary {
  min-width: 230px;
}

.contact-info-one .btn--secondary:hover, .contact-info-two .btn--secondary:hover, .contact-info-three .btn--secondary:hover {
  background: #086AD8;
}

.contact-info-two .icon {
  color: #fff;
}

.contact-info-two .heading {
  color: rgba(255, 255, 255, 0.8);
}

.contact-info-two .call-us {
  color: #d2a98e;
}

.contact-info-two .call-us:hover {
  color: #d2a98e;
}

.contact-info-two .call-us a:hover {
  color: #d2a98e;
}

.contact-info-two .btn--secondary {
  background: #ffffff;
  color: #086AD8;
}

.contact-info-two .btn--secondary:hover {
  background: #d2a98e;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .contact-info-three {
    margin-top: 60px;
  }
}

.contact-info-three .heading {
  margin-bottom: 30px;
}

.contact-info-three .call-us {
  margin-bottom: 20px;
}

.contact-info-three .location-text-button .button-icon {
  height: 56px;
  width: 56px;
  line-height: 56px;
  border-radius: 50%;
  text-align: center;
  background: #f6f2ed;
  margin-right: 14px;
  display: inline-block;
}

.contact-info-three .location-text-button .button-icon::before {
  content: '\f3c5';
  font-style: normal;
  line-height: 56px;
  text-align: center;
  color: #086AD8;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.contact-info-three .location-text-button .button-text {
  font-weight: 500;
  color: #086AD8;
  position: relative;
}

.contact-info-three .location-text-button .button-text::after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.contact-info-three .location-text-button:hover .button-text::after {
  width: 100%;
  left: 0;
  right: auto;
}

.processing-computing-area {
  padding: 150px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .processing-computing-area {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .processing-computing-area {
    padding: 60px 0;
  }
}

.computing-info-box h2 {
  font-size: 64px;
  line-height: 1.13;
  color: #002fa6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .computing-info-box h2 {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .computing-info-box h2 {
    font-size: 32px;
  }
}

.contact-form-service-wrap {
  background: #fff;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  padding: 42px 50px 50px;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .contact-form-service-wrap {
    padding: 42px 20px 50px;
  }
}

.business-solution-form-wrap {
  max-width: 400px;
  margin-left: auto;
  background: #fff;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  padding: 42px 30px 51px;
  border-radius: 5px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .business-solution-form-wrap {
    margin: auto;
  }
}

.ht-star-rating.lg-style {
  color: #fb0;
  font-size: 24px;
}

.ht-star-rating.lg-style span + span {
  margin-left: 11px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .contact-info {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .contact-info.sytle-one, .contact-info.style-two {
    margin-top: 60px;
  }
  .contact-info.sytle-one.service-contact, .contact-info.style-two.service-contact {
    margin-top: 0;
  }
}

.contact-info.sytle-one .contact-info-title-wrap .sub-text, .contact-info.style-two .contact-info-title-wrap .sub-text {
  margin-top: 20px;
  color: #ffffff;
  font-size: 18px;
}

.contact-info.sytle-one .contact-list-item .single-contact-list, .contact-info.style-two .contact-list-item .single-contact-list {
  display: block;
}

.contact-info.sytle-one .contact-list-item .content, .contact-info.style-two .contact-list-item .content {
  display: flex;
  margin-top: 55px;
}

.contact-info.sytle-one .contact-list-item .content .icon, .contact-info.style-two .contact-list-item .content .icon {
  color: #fff;
  border-color: #fff;
  font-size: 40px;
  margin-right: 10px;
  flex-shrink: 0;
  min-width: 54px;
}

.contact-info.sytle-one .contact-list-item .content .main-content .heading, .contact-info.style-two .contact-list-item .content .main-content .heading {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.78;
  margin-bottom: 13px;
  color: rgba(255, 255, 255, 0.8);
}

.contact-info.sytle-one .contact-list-item .content .main-content .text, .contact-info.style-two .contact-list-item .content .main-content .text {
  color: #d2a98e;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.17;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info.sytle-one .contact-list-item .content .main-content .text, .contact-info.style-two .contact-list-item .content .main-content .text {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info.sytle-one .contact-list-item .content .main-content .text, .contact-info.style-two .contact-list-item .content .main-content .text {
    font-size: 24px;
  }
}

.contact-info.style-two .contact-info-title-wrap .sub-text {
  color: #696969;
}

.contact-info.style-two .contact-list-item .content .icon {
  color: #d2a98e;
}

.contact-info.style-two .contact-list-item .content .main-content .heading {
  color: #333333;
}

.contact-info.style-two .contact-list-item .content .main-content .text {
  color: #086AD8;
}

.conact-info__list li {
  margin-bottom: 7px;
}

.conact-us-wrap-one.managed-it .heading {
  line-height: 1.67;
  font-weight: 800;
}

/*=====================================
=        34. Blog Pages Css           =
======================================*/
.single-blog-item.lg-blog-item {
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.single-blog-item.lg-blog-item:last-child {
  padding-bottom: 60px;
  margin-bottom: 0px;
}

.single-blog-item.xs-list-blog-item {
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.single-blog-item.xs-list-blog-item:last-child {
  margin-bottom: 0;
}

.single-blog-item .post-feature img {
  border-radius: 5px;
}

.single-blog-item .post-quote {
  position: relative;
  padding: 78px 40px 53px;
  background: #f5f7fd;
  text-align: center;
}

.single-blog-item .post-quote .post-quote-text {
  line-height: 1.5;
  margin-bottom: 39px;
}

.single-blog-item .post-quote::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 54px;
  transform: translateX(-50%);
  height: 132px;
  width: 150px;
  background: url(../images/icons/quote.png) no-repeat;
}

.single-blog-item .post-quote .post-quote-name {
  color: #333;
}

.single-blog-item .post-info {
  margin-top: 20px;
}

.single-blog-item .post-info .post-categories {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: #d2a98e;
}

.single-blog-item .post-info .post-title {
  margin-bottom: 15px;
}

.single-blog-item .post-info .post-meta {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.single-blog-item .post-info .post-meta .post-author {
  padding: 5px 10px 0;
}

.single-blog-item .post-info .post-meta .post-author .avatar-96 {
  width: 32px;
  vertical-align: middle;
  margin-right: 6px;
  border-radius: 50%;
}

.single-blog-item .post-info .post-meta .post-date, .single-blog-item .post-info .post-meta .post-view {
  padding: 5px 10px;
}

.single-blog-item .post-info .post-meta .post-date .meta-icon, .single-blog-item .post-info .post-meta .post-view .meta-icon {
  margin-right: 6px;
}

.single-blog-item .post-info .post-meta .post-comments {
  padding: 5px 10px;
}

.single-blog-item .post-info .post-meta .post-comments .meta-icon {
  margin-right: 6px;
}

.single-blog-item.blog-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 30px;
}

.single-blog-item.blog-grid:hover .post-feature {
  transform: translateY(-5px);
}

.single-blog-item.blog-grid .post-info .post-title {
  margin-bottom: 10px;
  line-height: 1.5;
}

.single-blog-item.blog-grid .post-info .btn-text {
  margin-top: 10px;
}

.single-blog-item.blog-grid .post-info .btn-text a {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
}

.single-blog-item.blog-grid .post-info .btn-text a:hover {
  color: #d2a98e;
}

.single-blog-item.blog-masonry {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  position: relative;
  margin-bottom: 30px;
}

.single-blog-item.blog-masonry .post-info {
  padding: 10px 25px 83px;
}

.single-blog-item.blog-masonry .post-info .post-title {
  line-height: 1.5;
}

.single-blog-item .post-read-more .btn-read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 56px;
  line-height: 55px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #eee;
  color: #086AD8;
  display: block;
}

.single-blog-item .post-read-more .btn-read-more::before {
  background: #086AD8;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.single-blog-item .post-read-more .btn-read-more:hover {
  color: #ffffff;
}

.single-blog-item .post-read-more .btn-read-more:hover::before {
  transform: translateY(0);
}

.post-list-wrapper .blog-thumbnail a {
  display: block;
}

.post-list-wrapper .blog-thumbnail a img {
  width: 100%;
}

.post-list-wrapper .post-feature {
  margin-right: 30px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .post-list-wrapper .post-feature {
    margin-right: 0px;
  }
}

.post-list-wrapper .post-info {
  margin-top: 0px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .post-list-wrapper .post-info {
    margin-top: 25px;
  }
}

.quote-post-wrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;
}

.quote-post-wrapper::before {
  content: '';
  padding-top: 100%;
}

.quote-post-wrapper .post-overlay {
  background: #f5f7fd;
  transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.quote-post-wrapper .post-info {
  padding: 74px 64px 51px;
}

.quote-post-wrapper .post-quote-text {
  font-size: 24px;
  line-height: 1.5;
}

.quote-post-wrapper .post-content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}

.quote-post-wrapper .post-content ::before {
  content: '';
  position: absolute;
  top: 36px;
  left: 0;
  background: url(../images/icons/metro-quote.png) no-repeat;
  width: 150px;
  height: 132px;
}

.quote-post-wrapper .post-quote-name {
  font-size: 24px;
  color: #333;
  margin: 170px 0 0;
}

.single-valid-post-wrapper {
  overflow: hidden;
}

.single-valid-post-wrapper:hover .single-valid__thum {
  transform: scale(1.05, 1.05);
}

.single-valid-post-wrapper .post-content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}

.single-valid-post-wrapper .post-info {
  padding: 30px 30px 48px;
  width: 100%;
  color: #fff;
}

.single-valid-post-wrapper .post-info .post-meta {
  display: flex;
  flex-wrap: wrap;
}

.single-valid-post-wrapper .post-info .post-meta .post-date {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.single-valid-post-wrapper .post-info .post-meta .post-date .meta-icon {
  margin-right: 6px;
}

.single-valid-post-wrapper .post-info .post-meta .post-categories {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.single-blog__item {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
}

.single-blog__item::before {
  content: '';
}

.single-blog__item::before {
  padding-top: 30%;
}

.single-blog__item .single-valid__thum {
  transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-blog__item .single-valid__thum::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(-180deg, transparent 27%, rgba(0, 0, 0, 0.28) 100%);
}

blockquote {
  margin: 54px 65px 44px 70px !important;
  padding: 0 0 0 20px;
  font-size: 18px;
  line-height: 1.78;
  font-weight: 600;
  border-left: 4px solid #eee;
  border-left-color: #d2a98e;
  position: relative;
  color: #333;
}

@media only screen and (max-width: 767px) {
  blockquote {
    margin: 54px 65px 44px 30px !important;
  }
}

.entry-post-tags {
  display: flex;
}

.entry-post-tags .tagcloud-icon {
  font-size: 16px;
  margin-right: 9px;
  margin-bottom: 0;
}

.entry-post-tags .tagcloud a:hover {
  color: #086AD8;
}

.entry-post-share-wrap {
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e8f6;
  margin-bottom: 50px;
}

.entry-post-share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .entry-post-share {
    justify-content: flex-start;
    margin-top: 20px;
  }
}

.entry-post-share .share-media .share-icon {
  color: #fff;
  background: #086AD8;
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 15px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

.entry-post-share .share-media {
  position: relative;
  margin-left: 10px;
}

.entry-post-share .share-list {
  position: absolute;
  bottom: 100%;
  right: 0%;
  transform: translate(0%, -1px);
  width: auto;
  white-space: nowrap;
  padding: 0 4px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
  user-select: none;
  filter: drop-shadow(0 2px 20px rgba(0, 0, 0, 0.06));
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.entry-post-share .share-list::before {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(100%);
  content: '';
  border-top: 8px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.entry-post-share .share-list a {
  display: inline-block;
  font-size: 13px;
  padding: 12px;
  color: #555;
}

.entry-post-share.opened .share-list {
  transform: translate(0%, -12px);
  visibility: visible;
  opacity: 1;
}

.entry-author .author-info {
  display: flex;
}

.entry-author .author-avatar {
  flex-shrink: 0;
  width: 170px;
  text-align: center;
}

.entry-author .author-avatar img {
  border-radius: 50%;
}

@media only screen and (max-width: 575px) {
  .entry-author .author-avatar {
    width: 120px;
  }
}

.entry-author .author-social-networks .inner {
  display: inline-block;
  margin: 22px -9px 0;
}

.entry-author .author-social-networks .inner a {
  display: block;
  float: left;
  padding: 6px 9px;
  font-size: 13px;
  line-height: 1;
  color: #333;
}

.entry-author .author-biographical-info {
  margin-top: 15px;
  font-size: 15px;
  color: #333;
}

.related-posts-wrapper {
  margin-top: 66px;
}

.related-posts-wrapper .single-valid-post-wrapper {
  position: relative;
  height: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  overflow: hidden;
  display: block;
}

.related-posts-wrapper .single-valid-post-wrapper::before {
  opacity: .5;
  background-image: linear-gradient(-180deg, rgba(51, 51, 51, 0) 0%, #000 80%);
  border-radius: 5px;
}

.related-posts-wrapper:hover {
  transform: translateY(-5px);
}

.related-posts-wrapper .single-valid__thum {
  display: flex;
  align-items: center;
  min-height: 120px;
  background-color: #333;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  overflow: hidden;
}

.related-posts-wrapper .post-content {
  padding-left: 50px;
  padding-right: 20px;
  margin-top: 0px;
  position: relative;
}

.related-posts-wrapper .post-content::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

.related-posts-wrapper .post-content .post-title {
  color: #ffffff;
}

.comment-list-wrapper {
  margin-top: 66px;
}

.comment-list {
  margin: 0;
  padding: 0;
}

.comment-list .comment {
  list-style-type: none;
  padding: 25px 0;
}

.comment-list .comment:last-child {
  padding-bottom: 0;
}

.comment-list .comment-author {
  float: left;
}

.comment-list .comment-author img {
  border-radius: 50px;
}

.comment-list .comment-content {
  position: relative;
  overflow: hidden;
  margin-left: 100px;
}

.comment-list .meta {
  margin-bottom: 12px;
}

.comment-list .meta .fn {
  font-size: 15px;
  text-transform: uppercase;
  color: #333;
  display: inline-block;
  margin-bottom: 0;
}

.comment-list .meta .comment-datetime {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  margin-left: 10px;
  font-size: 14px;
  font-style: italic;
  color: #ababab;
}

.comment-list .meta .comment-datetime::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  width: 3px;
  height: 3px;
  background: #d8d8d8;
  border-radius: 50%;
}

.comment-list .comment-actions a {
  margin-right: 20px;
  font-weight: 500;
  color: #333;
}

.comment-list .comment-actions a:hover {
  color: #086AD8;
}

.comment-list .children {
  margin: 20px 0 20px 50px;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comment-list .children {
    margin: 40px 0 20px 40px;
  }
}

.comment-list .children li + li {
  margin-top: 35px;
}

.comment-list .comment-actions {
  margin-top: 16px;
  margin-top: 16px;
  color: #ababab;
}

.comment-list .comment-actions .comment-datetime {
  display: inline-block;
  margin-right: 10px;
}

.comment-list .comment-actions .comment-reply-link {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 700;
}

.comment-submit-btn .ht-btn {
  padding: 0 54px;
}

.post-feature-details.post-quote {
  position: relative;
  padding: 48px 40px;
  background: #5945e6;
  text-align: center;
}

.post-feature-details.post-quote::before {
  display: none;
}

.post-feature-details.post-quote .quote-icon {
  color: #fff;
  font-size: 26px;
  margin-bottom: 35px;
}

.post-feature-details.post-quote .post-quote-text {
  line-height: 1.5;
  margin-bottom: 25px;
  color: #ffffff;
}

.post-feature-details.post-quote .post-quote-name {
  color: #ffffff;
}

.post-feature-details.post-link {
  padding: 82px 100px;
  background: #f7fbfe;
  font-size: 24px;
  line-height: 1.5;
}

.post-feature-details.post-link a {
  color: #38cb89;
  word-wrap: anywhere;
  font-weight: 400;
}

.post-feature-details.post-link::before {
  display: none;
}

.peatures_image-wrap {
  margin-right: -168px !important;
  margin-left: 80px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

/*=================================
=         35. Infotechno Css        =
===================================*/
.infotechno-bg {
  background: #f4efe9 none repeat scroll 0% 0%;
}

.infotechno-hero-text h6 {
  color: #086AD8;
  font-weight: 500;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .infotechno-hero-text {
    text-align: center;
    margin-top: 50px;
  }
}

.infotechno-hero-inner-images {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .infotechno-hero-inner-images {
    margin-top: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .infotechno-hero-inner-images {
    max-width: 327px;
    margin: auto;
  }
  .infotechno-hero-inner-images .infotechno-inner-one {
    max-width: 260px;
    margin: auto;
  }
}

.infotechno-inner-one {
  position: absolute;
  top: 10px;
}

.infotechno-inner-two {
  z-index: 1;
  position: relative;
}

.infotechno-hero-text {
  max-width: 605px;
  float: right;
  width: 100%;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .infotechno-hero-text {
    padding-left: 0;
    float: inherit;
    margin: 50px auto 20px;
  }
}

/*-------------------------
    video section css
--------------------------*/
.infotechno-section-bg-01 {
  background: url(../images/patterns/mitech-home-infotechno-section-company-bg.png) top 140px left no-repeat, -webkit-linear-gradient(top, #FFF 0, #F5F5F5 100%);
}

.infotechno-video-bg {
  background-image: url(../images/bg/home-infotechno-video-intro-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.infotechno-video-ptb {
  padding: 150px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .infotechno-video-ptb {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.vieeo-content-inner .heading {
  line-height: 1.34;
  font-weight: 600;
  color: #fff;
  margin-bottom: 65px;
}

.viewo-text-wrap .single-popup-wrap.video-link {
  display: inline-block;
}

.viewo-text-wrap .single-popup-wrap.video-link .ht-popup-video.video-button {
  position: relative;
  margin-right: 0;
  display: inline-block;
  height: 78px;
  width: 78px;
}

.viewo-text-wrap .video-content {
  display: flex;
  align-items: center;
}

.viewo-text-wrap .video-text {
  margin-top: 0;
  margin-left: 59px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.45;
}

.infotechno-blog-list li {
  position: relative;
}

.infotechno-blog-list li:first-child a {
  margin-top: -16px;
}

.infotechno-blog-list li a {
  position: relative;
  font-size: 15px;
  font-weight: 600;
  padding: 0 0 0 30px;
  margin-top: 20px;
}

.infotechno-blog-list li a::before, .infotechno-blog-list li a::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.infotechno-blog-list li a::after, .infotechno-blog-list li a::before {
  content: '\f30b';
  position: absolute;
  top: 7px;
  left: 0;
}

.infotechno-blog-list li a::before {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.infotechno-blog-list li a:hover::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.infotechno-blog-list li a:hover::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/*=============================================
=         36. Processing Hero Css             =
=============================================*/
.processing-hero-bg {
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../images/hero/slider-processing-slide-01-bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
}

.processing-hero-text {
  max-width: 730px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .processing-hero-text {
    margin-top: 60px;
    text-align: center;
  }
}

.processing-hero-text h6 {
  line-height: 36px;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.processing-hero-text h1 {
  color: #ffffff;
  font-weight: 500;
  font-size: 68px;
  letter-spacing: 0;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .processing-hero-text h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .processing-hero-text h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .processing-hero-text h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .processing-hero-text h1 {
    font-size: 34px;
  }
}

.processing-hero-text p {
  color: #ffffff;
  line-height: 32px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
}

.processing-hero-images-wrap {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .processing-hero-images-wrap {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .processing-hero-images-wrap {
    margin-top: 30px;
  }
}

.processing-hero-images {
  margin-left: -130px;
  margin-right: -200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .processing-hero-images {
    margin-left: -80px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .processing-hero-images {
    margin-left: -80px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .processing-hero-images {
    margin-left: -50px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .processing-hero-images {
    width: 300px;
    margin: auto;
  }
}

.hero-button {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .hero-button {
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .hero-button {
    justify-content: center;
  }
  .hero-button .btn {
    min-width: 140px;
    padding: 0 24px;
  }
}

.hero-popup-video {
  margin-left: 20px;
}

.hero-popup-video a {
  display: inline-block;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hero-popup-video a .video-content {
  display: flex;
}

.hero-popup-video a .video-content .video-text {
  margin-left: 8px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.hero-popup-video .video-play {
  font-size: 10px;
  display: inline-block;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #fff;
}

.hero-popup-video .video-play .video-play-icon i {
  color: #ffffff;
}

/*===============================
    Video Banner Section Css
=================================*/
.rv-video-section {
  position: relative;
}

.rv-video-section .main-video-box {
  position: relative;
  z-index: 8;
  text-align: center;
  max-width: 570px;
  max-height: 350px;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 180px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .rv-video-section .main-video-box {
    max-width: 520px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rv-video-section .main-video-box {
    max-width: 400px;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .rv-video-section .main-video-box {
    max-width: 400px;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .rv-video-section .main-video-box {
    max-width: 280px;
    margin-bottom: 100px;
  }
}

.rv-video-section .ht-banner-01 {
  position: relative;
}

.rv-video-section .ht-banner-01 img {
  position: absolute;
  left: 6%;
  top: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .rv-video-section .ht-banner-01 img {
    left: 6%;
    top: -50px;
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rv-video-section .ht-banner-01 img {
    left: 0%;
    top: -50px;
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .rv-video-section .ht-banner-01 img {
    left: 10%;
    top: -30px;
    width: 184px;
  }
}

@media only screen and (max-width: 575px) {
  .rv-video-section .ht-banner-01 img {
    left: 0;
    top: -30px;
    width: 184px;
  }
}

.rv-video-section .ht-banner-02 {
  position: relative;
}

.rv-video-section .ht-banner-02 img {
  position: absolute;
  left: 50%;
  top: -103px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .rv-video-section .ht-banner-02 img {
    position: absolute;
    left: 50%;
    top: -103px;
    max-width: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .rv-video-section .ht-banner-02 img {
    top: -65px;
    max-width: 130px;
  }
}

@media only screen and (max-width: 575px) {
  .rv-video-section .ht-banner-02 img {
    top: -65px;
    max-width: 130px;
  }
}

.rv-video-section .ht-banner-03 {
  position: relative;
}

.rv-video-section .ht-banner-03 img {
  position: absolute;
  left: 10%;
  bottom: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rv-video-section .ht-banner-03 img {
    bottom: 0;
    top: inherit;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rv-video-section .ht-banner-03 img {
    bottom: 0;
    top: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .rv-video-section .ht-banner-03 img {
    bottom: 0;
    max-width: 120px;
    top: inherit;
  }
}

@media only screen and (max-width: 575px) {
  .rv-video-section .ht-banner-03 img {
    bottom: 0;
    max-width: 120px;
    top: inherit;
  }
}

.rv-video-section .ht-banner-04 img {
  right: 50px;
  left: auto;
  position: absolute;
  top: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rv-video-section .ht-banner-04 img {
    right: 35px;
    max-width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rv-video-section .ht-banner-04 img {
    right: 35px;
    top: 80%;
    max-width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .rv-video-section .ht-banner-04 img {
    right: 15px;
    top: 80%;
    max-width: 140px;
  }
}

@media only screen and (max-width: 575px) {
  .rv-video-section .ht-banner-04 img {
    right: 10px;
    top: 80%;
    max-width: 110px;
  }
}

.animation_images {
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-name: animateUpDown;
  animation-iteration-count: infinite;
}

.animation_images.one {
  animation-duration: 3.2s;
}

.animation_images.two {
  animation-duration: 3.8s;
}

.animation_images.three {
  animation-duration: 3.4s;
}

.animation_images.four {
  animation-duration: 3.4s;
}

@-webkit-keyframes animateUpDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes animateUpDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*=============================================
=         37. Processing Hero Css             =
=============================================*/
.appointment-hero-bg {
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../images/hero/home-appointment-hero-bg-image.jpg");
  background-size: cover;
  background-position: 50% 50%;
}

@media only screen and (max-width: 767px) {
  .appointment-hero-text {
    text-align: center;
    margin-bottom: 60px;
  }
}

.hero-button .video-content {
  display: flex;
  align-items: center;
}

.hero-button .video-link .ht-popup-video .video-button__two .video-play {
  background: #ffffff;
}

.hero-button .video-link .ht-popup-video .video-button__two .video-play-icon::before {
  border-left-color: #086AD8;
}

.hero-button .single-popup-wrap.video-link .ht-popup-video.video-button {
  position: relative;
  width: 78px;
  height: 78px;
}

.hero-button .video-text {
  margin-top: 0;
  margin-left: 35px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 1.78;
}

.hero-button .ht-popup-video.video-button .video-mark .wave-pulse::after, .hero-button .ht-popup-video.video-button .video-mark .wave-pulse::before {
  border: 3px solid #ffffff;
  animation: zoomBig 3.25s linear infinite;
  animation-delay: 0s;
}

.hero-button .ht-popup-video.video-button .video-mark .wave-pulse::before {
  animation-delay: .75s;
}

/*=================================
=       38. Service Home Css        =
==================================*/
.service-hero-bg {
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../images/hero/home-services-hero-bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
}

.service-hero-space {
  height: 770px;
  display: block;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .service-hero-space {
    height: 670px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-hero-space {
    height: 570px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-hero-space {
    height: 520px;
  }
}

@media only screen and (max-width: 767px) {
  .service-hero-space {
    height: 480px;
  }
}

@media only screen and (max-width: 575px) {
  .service-hero-space {
    height: 480px;
  }
}

.service-hero-text {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .service-hero-text {
    text-align: center;
  }
}

.service-hero-text h3 {
  line-height: 36px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-hero-text h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service-hero-text h3 {
    font-size: 22px;
  }
}

.service-hero-text h1 {
  color: #ffffff;
  font-weight: 700;
  font-size: 120px;
  line-height: 1.09;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .service-hero-text h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-hero-text h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-hero-text h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .service-hero-text h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .service-hero-text h1 {
    font-size: 44px;
  }
}

.service-hero-text .service-solution-form-wrap {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}

.service-hero-text .service-solution-form-wrap select {
  border-color: #086ad8;
  background-color: #086ad8;
  background-image: url("../images/icons/hero-selector-icon.png");
  color: #fff;
  font-weight: 500;
  padding: 3px 30px;
  height: 60px;
}

.service-hero-text .service-solution-form-wrap select:focus {
  background: #086ad8 url("../images/icons/hero-selector-icon.png") no-repeat center right 20px !important;
}

.service-hero-text p {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 500;
}

.service-project-slide-info {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .service-project-slide-info {
    margin-bottom: 40px;
    margin-top: 0px;
  }
}

.service-project-slide-info .text {
  font-size: 18px;
  line-height: 1.67;
}

.single-blog-lg-item > a {
  display: block;
}

.single-blog-lg-item .btn-text a {
  display: inline-block;
  border-bottom: 1px solid #ddd;
}

.single-blog-lg-item .btn-text a:hover {
  color: #d2a98e;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-blog-lg-item {
    margin-bottom: 40px;
  }
}

.post-blog-thumbnail {
  position: relative;
  display: block;
}

.post-blog-thumbnail > img {
  border-radius: 5px;
  width: 100%;
}

.post-blog-thumbnail::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
  background-image: linear-gradient(-180deg, rgba(51, 51, 51, 0) 11%, #000 80%);
  border-radius: 5px;
}

.post-blog-thumbnail .post-meta {
  position: absolute;
  bottom: 18px;
  left: 30px;
  right: 30px;
  color: #fff;
  font-size: 14px;
  margin: 0 -8px;
}

.post-blog-thumbnail .post-meta .post-author img {
  width: 32px;
  vertical-align: middle;
  margin-right: 6px;
  border-radius: 50%;
}

.post-blog-thumbnail .post-meta div {
  display: inline-block;
  padding: 0 8px;
}

.single-blog-lg-list {
  display: flex;
  border-left: 1px solid #ededed;
  border-top: 1px solid #ededed;
  padding: 20px 0 20px 30px;
}

.single-blog-lg-list:first-child {
  border-top: none;
  padding: 0px 0 20px 30px;
}

@media only screen and (max-width: 575px) {
  .single-blog-lg-list:first-child {
    padding: 20px 0 20px 0px;
  }
}

.single-blog-lg-list .post-blog-thumbnail {
  position: relative;
  flex-shrink: 0;
  margin-right: 29px;
  width: 200px;
}

@media only screen and (max-width: 575px) {
  .single-blog-lg-list {
    flex-direction: column;
    border-left: none;
    border-top: none;
    padding: 0px 0 20px 0px;
  }
  .single-blog-lg-list .post-blog-thumbnail {
    margin-bottom: 20px;
    margin-right: 0px;
    width: 100%;
  }
}

.our-company-history {
  position: relative;
}

.grid-wrapper {
  max-width: 1200px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 25px;
  right: 25px;
}

.grid-wrapper .line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(204, 204, 204, 0.25);
}

.grid-wrapper .line-1 {
  left: 25px;
}

.grid-wrapper .line-2 {
  left: 33.33333%;
}

.grid-wrapper .line-3 {
  left: 66.66666%;
}

.grid-wrapper .line-4 {
  right: 25px;
}

/*=============================================
=        38. Resolutions home Css             =
=============================================*/
.resolutions-hero-bg {
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../images/hero/home-resolutions-hero-bg-image.jpg");
  background-size: cover;
  background-position: 50% 50%;
}

.resolutions-hero-bg .vc_row-separator svg {
  fill: #F8F8F8;
}

.resolutions-hero-space {
  height: 880px;
  display: block;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .resolutions-hero-space {
    height: 740px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .resolutions-hero-space {
    height: 670px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .resolutions-hero-space {
    height: 520px;
  }
}

@media only screen and (max-width: 767px) {
  .resolutions-hero-space {
    height: 480px;
  }
}

@media only screen and (max-width: 575px) {
  .resolutions-hero-space {
    height: 480px;
  }
}

.hero-button-group .ht-btn {
  margin: 10px;
  font-size: 14px;
}

.hero-button-group .ht-btn.btn--white {
  color: #086AD8;
}

.hero-button-group .ht-btn.btn--white .btn-icon {
  font-size: 10px;
  margin-left: 0px;
}

.hero-button-group .ht-btn.btn--white:hover {
  background: #086AD8;
  color: #ffffff;
}

.resolutions-hero-bottom {
  margin-top: -160px;
  position: relative;
  z-index: 4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .resolutions-hero-bottom {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .resolutions-hero-bottom {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .resolutions-hero-bottom {
    margin-top: 0px;
  }
}

.resolutions-hero-slider .vc_row-separator svg {
  fill: #F8F8F8;
}

.resolutions-hero-bg-2 {
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../images/hero/home-resolutions-hero-bg-image.jpg");
  background-size: cover;
  background-position: 50% 50%;
}

/*=============================================
=        39. Cybersecurity home Css             =
=============================================*/
.cybersecurity-hero {
  overflow: hidden;
  background: #F6FAFE;
}

.cybersecurity-hero-text {
  max-width: 680px;
  width: 100%;
  float: right;
  padding-left: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cybersecurity-hero-text {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .cybersecurity-hero-text {
    padding-left: 0;
    float: inherit;
    margin: 60px auto 0px;
    text-align: center;
  }
}

.cybersecurity-hero-text h6 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 20px;
}

.cybersecurity-hero-text h3 {
  font-weight: 700;
}

.cybersecurity-hero-images {
  position: relative;
}

.inner-img-one {
  position: absolute;
  z-index: -1;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .inner-img-one {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
  .inner-img-one img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .inner-img-two {
    margin: auto !important;
    text-align: center;
  }
  .inner-img-two img {
    max-width: 254px;
  }
}

.worldRotate {
  -webkit-animation: worldRotate 20s linear infinite;
  animation: worldRotate 20s linear infinite;
}

/*--- spinner keyframe ---*/
@keyframes worldRotate {
  0% {
    transform: rotate(0deg) translateX(0px) rotate(0deg);
  }
  100% {
    transform: rotate(0deg) translateY(0px) rotate(-360deg);
  }
}

/*=====================================
=      40. About Us pages Css           =
=======================================*/
.about-us-bg {
  background-image: url(../images/bg/contact-us-01-hero-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-space {
  padding-top: 195px;
  padding-bottom: 204px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-space {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-space {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-space {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.vision-space {
  padding-top: 85px;
  padding-bottom: 195px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vision-space {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vision-space {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .vision-space {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.requirements-list {
  width: 600px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100%;
  float: right;
}

.resources-left-box {
  max-width: 600px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  float: right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .resources-left-box {
    margin: 0 auto;
    float: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .resources-left-box {
    text-align: center;
    margin: 0 auto;
    float: none;
  }
}

@media only screen and (max-width: 767px) {
  .resources-left-box {
    margin: 0 auto;
    margin-bottom: 0px;
    float: none;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
    text-align: center;
  }
}

.resources-inner .sub-title {
  color: #ababab;
}

.about-resources-wrapper {
  padding: 40px 0;
  background-color: #f8f8f8;
  background-image: url(../images/bg/mitech-slider-cybersecurity-global-image.png);
  background-repeat: no-repeat;
  background-position: center right 40px;
}

.delivering-optimal-wrap .marker::before {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  color: #d2a98e;
}

.delivering-optimal-wrap .list-item {
  padding-left: 40px;
  margin-top: 40px;
  position: relative;
}

.delivering-optimal-wrap .list-item .title-wrap .title {
  color: #086AD8;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .modern-number-01 {
    margin-bottom: 20px;
  }
}

.modern-number-01.number-two h2 {
  max-width: 330px;
}

.modern-number-01.number-two span.mark-text {
  color: #086AD8;
}

.contact-button a {
  color: #086AD8;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.45;
  border-bottom: 1px solid #ddd;
}

.about-button {
  text-align: right;
}

.about-button a {
  min-width: 170px;
}

.gallery-warp {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.gallery-warp:nth-child(4n+1) {
  grid-column: span 8;
}

.gallery-grid {
  display: block;
  width: 100%;
  height: 100%;
}

.gallery-grid .single-gallery__thum {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  max-height: 420px;
}

.gallery-grid:nth-child(4n+1) {
  -ms-grid-column: span 8;
  grid-column: span 8;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .gallery-grid:nth-child(4n+1) {
    grid-column: span 6;
  }
}

@media only screen and (max-width: 575px) {
  .gallery-grid:nth-child(4n+1) {
    grid-column: span 12;
  }
}

.gallery-grid:nth-child(4n+2) {
  -ms-grid-column: span 4;
  grid-column: span 4;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .gallery-grid:nth-child(4n+2) {
    grid-column: span 6;
  }
}

@media only screen and (max-width: 575px) {
  .gallery-grid:nth-child(4n+2) {
    grid-column: span 12;
  }
}

.gallery-grid:nth-child(4n+3) {
  -ms-grid-column: span 6;
  grid-column: span 6;
}

@media only screen and (max-width: 575px) {
  .gallery-grid:nth-child(4n+3) {
    grid-column: span 12;
  }
}

.gallery-grid:nth-child(4n+4) {
  -ms-grid-column: span 6;
  grid-column: span 6;
}

@media only screen and (max-width: 575px) {
  .gallery-grid:nth-child(4n+4) {
    grid-column: span 12;
  }
}

.single-gallery {
  overflow: hidden;
  display: block;
  border-radius: 5px;
}

.single-gallery__item {
  position: relative;
  height: 100%;
}

.single-gallery__item .ht-overlay {
  background-color: #086AD8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-gallery__item .overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-gallery__item .overlay-icon i {
  font-size: 24px;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-gallery__item:hover .ht-overlay, .single-gallery__item:hover .overlay-icon {
  opacity: .9;
  visibility: visible;
}

.bg-item-images {
  position: relative;
  display: flex;
  align-items: stretch;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.bg-item-images::before {
  content: '';
  display: block;
  padding-top: 67%;
}

/*=======================================
=           Careers Job Listing         = 
========================================*/
.ht-simple-job-listing .item {
  border-radius: 5px;
  padding: 33px 50px 36px;
}

.ht-simple-job-listing .item:nth-child(2n) {
  background: #f6f2ed;
}

.ht-simple-job-listing .item .job-info .job-time {
  font-size: 18px;
  margin-top: 12px;
  display: block;
}

.ht-simple-job-listing .item .job-button .ht-btn {
  min-width: 155px;
}

@media only screen and (max-width: 767px) {
  .ht-simple-job-listing .item {
    padding: 30px;
  }
  .ht-simple-job-listing .item .job-description {
    margin-top: 15px;
  }
  .ht-simple-job-listing .item .job-button {
    margin-top: 20px;
  }
}

/*=======================================
=       41. Single Smart Vision Css         =
========================================*/
.vision-content .heading {
  font-size: 28px;
  line-height: 1.43;
  font-weight: 400;
  color: #002fa6;
}

.portfolio-details-table table {
  margin: 0 0 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  display: inline-table;
  overflow: hidden;
}

.portfolio-details-table .label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  color: #333;
  margin-bottom: 12px;
}

.portfolio-details-table td:last-child {
  text-align: right;
  padding-right: 0;
}

.portfolio-details-table td {
  border: 0;
  background: none !important;
  padding: 17px 10px;
  padding-right: 10px;
  text-align: left;
}

.portfolio-details-table td:first-child {
  padding-left: 0;
}

.portfolio-details-table tr + tr {
  border-top: 1px solid #ededed;
}

.portfolio-details-table .portfolio-details-share a {
  padding: 0 10px;
  font-size: 15px;
  color: #ababab;
}

.ht-problem-solution .item {
  border-bottom: 1px solid #eee;
  padding-bottom: 50px;
  margin-bottom: 44px;
}

.ht-problem-solution .item .problem {
  position: relative;
  padding-left: 32px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.74;
  margin-bottom: 20px;
}

.ht-problem-solution .item .problem::before {
  color: #086ad8;
  content: '\f178';
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 18px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.ht-problem-solution .item .solution {
  position: relative;
  padding-left: 32px;
}

.ht-problem-solution .item .solution::before {
  color: #086ad8;
  content: '\f00c';
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 18px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.results-wrap .text {
  font-size: 24px;
}

.entry-portfolio-return-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  background: #f6f2ed;
  box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
  font-size: 18px;
}

.entry-portfolio-return-link:hover {
  background: #086AD8;
  color: #ffffff;
}

.portfolio-nav-links .nav-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

@media only screen and (max-width: 767px) {
  .portfolio-nav-links .nav-list {
    grid-template-columns: auto;
  }
}

.portfolio-nav-links .nav-list .next {
  text-align: right;
}

.portfolio-nav-links .nav-list .inner div {
  display: flex;
  align-items: center;
  min-height: 190px;
  padding: 20px 0;
}

.portfolio-nav-links .nav-list .prev img {
  margin-right: 28px;
}

.portfolio-nav-links .nav-list .next img {
  margin-left: 28px;
  order: 2;
}

.portfolio-nav-links .nav-list .next a {
  padding-right: 30px;
}

.portfolio-nav-links .nav-list .prev a {
  padding-left: 30px;
}

.portfolio-nav-links .nav-list .inner h6::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}

.portfolio-nav-links .nav-list .prev h6::before {
  left: 15px;
  content: '\f104';
}

.portfolio-nav-links .nav-list .next h6::before {
  right: 15px;
  content: '\f105';
}

/*====================================
=         42. Redraw Css             =
=====================================*/
.rev_redraw-wrapper {
  overflow: hidden;
}

.rev_redraw-space {
  padding-top: 230px;
  padding-bottom: 230px;
}

@media only screen and (max-width: 767px) {
  .rev_redraw-space {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.redraw-content {
  z-index: 4;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .redraw-images img {
    max-width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .redraw-images img {
    max-width: 100px;
  }
}

.redrow-1 {
  overflow: hidden;
  top: -205%;
  position: absolute;
  left: 10%;
}

@media only screen and (max-width: 767px) {
  .redrow-1 {
    top: -150%;
  }
}

@media only screen and (max-width: 575px) {
  .redrow-1 {
    top: -130%;
  }
}

.redrow-2 {
  position: absolute;
  left: 40%;
  top: -150%;
}

@media only screen and (max-width: 767px) {
  .redrow-2 {
    top: -80%;
  }
}

.redrow-3 {
  top: -205%;
  position: absolute;
  right: 10%;
}

@media only screen and (max-width: 767px) {
  .redrow-3 {
    top: -150%;
  }
}

@media only screen and (max-width: 575px) {
  .redrow-3 {
    top: -130%;
  }
}

.redrow-4 {
  top: 0%;
  position: absolute;
  left: -10%;
}

.redrow-5 {
  position: absolute;
  left: 20%;
  top: 150%;
}

.redrow-6 {
  position: absolute;
  right: 20%;
  top: 150%;
}

.redrow-7 {
  top: 0%;
  position: absolute;
  right: -10%;
}

/*=====================================
=       43. Preview Page Css          =
======================================*/
.hero__purchase .btn:hover, .downlode-btn .btn:hover {
  background: #086AD8;
  color: #fff;
}

.header__actions--preview .ht-btn {
  background: #fff;
  color: #086AD8;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__actions--preview {
    flex-basis: 70%;
  }
  .header__actions--preview .header__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.preview-hero-area {
  height: 1020px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .preview-hero-area {
    height: 860px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .preview-hero-area {
    height: 780px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .preview-hero-area {
    height: 620px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-hero-area {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .preview-hero-area {
    height: auto;
  }
}

.preview-hero-bg {
  background: url(../images/hero/mitech-landing-main-slider-bg.jpg);
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
  background-repeat: no-repeat;
}

.pr-img-01 {
  position: absolute;
  top: -4%;
  left: 28%;
}

@media only screen and (max-width: 767px) {
  .pr-img-01 {
    width: 130px;
    top: -10%;
    left: 8%;
  }
}

.pr-img-02 {
  position: absolute;
  top: 12%;
  right: 6%;
}

@media only screen and (max-width: 767px) {
  .pr-img-02 {
    right: -5%;
  }
}

.pr-img-03 {
  position: absolute;
  bottom: 10%;
  right: -10%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-img-03 {
    width: 250px;
    right: -10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-img-03 {
    width: 250px;
    right: -10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-img-03 {
    width: 200px;
    right: -20%;
  }
}

@media only screen and (max-width: 767px) {
  .pr-img-03 {
    width: 200px;
    right: -20%;
  }
}

@media only screen and (max-width: 575px) {
  .pr-img-03 {
    width: 200px;
    right: -30%;
  }
}

.pr-img-04 {
  position: absolute;
  bottom: 20%;
  right: 18%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-img-04 {
    bottom: 5%;
    right: 18%;
  }
}

@media only screen and (max-width: 767px) {
  .pr-img-04 {
    bottom: 2%;
    right: 50%;
  }
}

.preview-inner-img img {
  z-index: 4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-inner-img {
    max-width: 500px;
    margin: auto;
  }
}

.preview-hero-text {
  z-index: 4;
}

.preview-hero-text h6 {
  color: #d2a98e;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-hero-text {
    text-align: center;
  }
  .preview-hero-text .hero-button {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .preview-hero-text {
    text-align: center;
  }
}

.single-preview-item__wrap a {
  display: block;
}

.single-preview-item__wrap a:hover {
  transform: translateY(-5px);
}

.single-preview-item__wrap a:hover .single-preview-item__thumbnail .overlay {
  visibility: visible;
  opacity: 1;
}

.frame-screen {
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 70px 40px -35px rgba(51, 51, 51, 0.13);
}

.frame-screen .dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 14px;
  height: 23px;
}

.frame-screen .dots .dot {
  margin: 0 4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #dadada;
}

.frame-screen .single-preview-item__thumbnail {
  position: relative;
}

.frame-screen .single-preview-item__thumbnail .overlay {
  background-color: rgba(8, 106, 216, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: visible;
  opacity: 0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.frame-screen .single-preview-item__thumbnail .btn-view-demo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff !important;
  pointer-events: none;
  min-width: 150px;
}

.frame-screen .single-preview-item__info h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.87;
  padding: 14px 27px;
  margin-bottom: 0;
  text-align: left;
}

.layout-section-bg {
  background: url(../images/preview/mitech-landing-header-preview-bg.png);
  background-color: #f6f2ed;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.layouts-section-text {
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.ht-box-icon.preview-style {
  margin-left: auto;
  max-width: 420px;
  margin-top: 30px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-icon.preview-style:hover {
  transform: translateY(-5px);
}

.ht-box-icon.preview-style .icon-box-wrap {
  display: flex;
}

.ht-box-icon.preview-style .icon-box-wrap .image {
  position: relative;
  flex-shrink: 0;
  margin: 0 27px 0 0;
  width: 114px;
  text-align: center;
  border-radius: 50%;
}

.prv-section-footer-bg {
  background: url(../images/preview/mitech-landing-footer-bg-01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-preview-content h6 {
  letter-spacing: 7px;
}

.badge-image {
  position: absolute;
  top: -5px;
  right: 0;
  z-index: 11;
}

/* ====================
    Toolbar Area 
========================*/
.aeroland__toolbar .inner {
  position: absolute;
  top: 200px;
  right: 100%;
  display: block;
  width: 40px;
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #fff;
  text-align: center;
  box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
}

.aeroland__toolbar .inner a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #222;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.demo-option-wrapper {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 55px;
}

.demo-option-wrapper .demo-panel-header {
  padding: 40px 30px 30px;
  text-align: center;
}

.demo-option-container {
  position: fixed;
  top: 0;
  left: 100%;
  background: #fff;
  height: 100%;
  width: 350px;
  max-width: calc(100% - 45px);
  z-index: 100000;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.demo-option-container.open {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
  box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
}

.demo-quick-option-list {
  padding: 27px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.demo-quick-option-list a img {
  border-radius: 5px;
}

.demo-quick-option-list a:hover img {
  transform: translateY(-3px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

/*=================================
=       Modern It Company Css       =
===================================*/
.modern-it-company-top-area {
  background-image: url("../images/bg/bg-gird-pattern-repeat-alt.png");
  background-repeat: repeat;
}

.modern-it-company-hero {
  position: relative;
  margin-bottom: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-it-company-hero {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-it-company-hero {
    margin-bottom: 30px;
  }
}

.modern-it-company-form-wrap {
  max-width: 550px;
}

@media (min-width: 1200px) {
  .modern-it-company-hero-image {
    position: absolute;
    top: 176px;
    right: 10px;
  }
}

.modern-it-company-hero-image {
  text-align: center;
  margin: auto;
  margin-top: 60px;
}

.modern-it-company-hero-text h1 {
  font-size: 65px;
  line-height: 1.2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-it-company-hero-text h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-it-company-hero-text h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-it-company-hero-text h1 {
    font-size: 34px;
  }
}

.modern-it-company-hero-text .sort-dec {
  margin-top: 30px;
}

.modern-it-company-hero-text .book-box {
  margin-top: 100px;
  margin-bottom: 30px;
  position: relative;
}

.modern-it-company-hero-text .book-box .image {
  position: absolute;
  top: -30px;
  left: -80px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-it-company-hero-text .book-box {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-it-company-hero-text .book-box {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

.modern-it-company-hero-text .book-text {
  max-width: 340px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-it-company-hero-text .book-text {
    max-width: 100%;
  }
}

.modern-it-company-testimonial-bg {
  background-image: url("../images/patterns/home-reputable-success-about-shape.jpg");
  background-repeat: no-repeat;
  background-position: left 44px bottom 40px;
}

.success-stories-wrap {
  display: flex;
  justify-content: center;
  padding: 58px 80px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #f6f2ed;
  background-image: url("../images/bg/home-reputable-success-section-story-bg.png");
  background-repeat: no-repeat;
  background-position: right top;
}

@media only screen and (max-width: 767px) {
  .success-stories-wrap {
    padding: 58px 40px;
    flex-direction: column;
  }
  .success-stories-wrap .heading br {
    display: none;
  }
}

.modern-it-newsletters-wrap {
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 40px 35px;
}

.modern-it-newsletters-wrap .contact-inner input {
  background-color: #fff;
}

/*=============================================
=         36. Processing Hero Css             =
=============================================*/
.machine-learning-hero-bg {
  background-image: url("../images/hero/new-slider-bg.png");
  background-color: #072a83;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 320px;
  padding-bottom: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .machine-learning-hero-bg {
    padding-top: 200px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .machine-learning-hero-bg {
    padding-top: 150px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .machine-learning-hero-bg {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .machine-learning-hero-bg {
    padding-top: 0px;
    padding-bottom: 80px;
  }
}

.machine-learning-hero-text {
  max-width: 730px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .machine-learning-hero-text {
    margin-top: 60px;
    text-align: center;
  }
}

.machine-learning-hero-text h6 {
  line-height: 36px;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.machine-learning-hero-text h1 {
  color: #ffffff;
  font-weight: 500;
  font-size: 68px;
  letter-spacing: 0;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .machine-learning-hero-text h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .machine-learning-hero-text h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .machine-learning-hero-text h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .machine-learning-hero-text h1 {
    font-size: 34px;
  }
}

.machine-learning-hero-text p {
  color: #ffffff;
  line-height: 32px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
}

.hero-button {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .hero-button {
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .hero-button {
    justify-content: center;
  }
  .hero-button .btn {
    min-width: 140px;
    padding: 0 24px;
  }
}

.hero-popup-video {
  margin-left: 20px;
}

.hero-popup-video a {
  display: inline-block;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hero-popup-video a .video-content {
  display: flex;
}

.hero-popup-video a .video-content .video-text {
  margin-left: 8px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.hero-popup-video .video-play {
  font-size: 10px;
  display: inline-block;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #fff;
}

.hero-popup-video .video-play .video-play-icon i {
  color: #ffffff;
}

.hero-brand-wrap {
  margin-top: 150px;
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-brand-wrap {
    margin-right: 30px;
    margin-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-brand-wrap {
    margin-right: 30px;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-brand-wrap {
    margin-top: 50px;
    justify-content: center;
  }
}

.hero-brand-wrap .brand-logo {
  position: relative;
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-brand-wrap .brand-logo {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-brand-wrap .brand-logo {
    margin: 0 10px;
  }
}

.hero-brand-wrap .brand-logo__image {
  transition: all .4s linear;
}

.hero-brand-wrap .brand-logo__image-hover {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8, 0.8);
  transition: all .4s linear;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.hero-brand-wrap .brand-logo:hover .brand-logo__image {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8, 0.8);
}

.hero-brand-wrap .brand-logo:hover .brand-logo__image-hover {
  opacity: 1;
  visibility: visible;
  transform: none !important;
  z-index: 3;
}

.machine-learning-about-bg {
  background-color: #f8fbfe;
  background-image: url("../images/bg/n-1-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.machine-learning-service-bg {
  background-color: #f8f8f8;
  background-image: url("../images/bg/n-2-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.machine-learning-mark-text {
  max-width: 370px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.67;
  color: #696969;
  padding-left: 16px;
  border-left: 2px solid #086ad8;
}

.machine-learning-about-content .dec-text {
  font-size: 18px;
}

.machine-learning-contact-us-bg {
  background-image: url("../images/bg/new-cta-bg.png");
  background-color: #086AD8;
  background-size: cover;
  background-repeat: no-repeat;
}

.machine-learning-project-bg {
  background-color: #f5f5f5;
  background-image: url("../images/bg/n-3-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.testimonials-contails-machine h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  letter-spacing: 3px;
  color: #ababab;
}

.testimonials-contails-machine p {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.22;
  color: #002fa6;
  margin-top: 20px;
  margin-bottom: 24px;
}

.testimonials-contails-machine .testimonial-info {
  display: flex;
  flex-wrap: wrap;
}

.testimonials-contails-machine .testimonial-info .testimonial-name {
  font-size: 15px;
  font-weight: 700;
  color: #454545;
  margin-top: 5px;
  margin-right: 10px;
  position: relative;
}

.testimonials-contails-machine .testimonial-info .testimonial-name::after {
  content: ' / ';
  left: 0;
}

.testimonials-contails-machine .testimonial-info .testimonial-by-line {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.29;
  color: #7e7e7e;
}

.swiper-pagination-machine {
  position: absolute;
  width: auto !important;
  margin-bottom: 50px;
}

.software-innovation-hero-text .sub-heading {
  text-transform: uppercase;
  color: #C2C2C2;
  line-height: 28px;
  letter-spacing: 3px;
  font-weight: 700;
}

.software-innovation-hero-text h3 {
  color: #005bc1;
  line-height: 40px;
  font-weight: 700;
  font-size: 40px;
  transform-origin: 50% 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .software-innovation-hero-text h3 {
    line-height: 40px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .software-innovation-hero-text h3 {
    font-size: 24px;
    line-height: 30px;
  }
}

.software-innovation-hero-text h1 {
  color: #005bc1;
  line-height: 130px;
  font-weight: 700;
  font-size: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .software-innovation-hero-text h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .software-innovation-hero-text h1 {
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .software-innovation-hero-text h1 {
    font-size: 64px;
    line-height: 70px;
  }
}

.software-innovation-hero-text .info-heading {
  color: #2a2a2a;
  line-height: 32px;
  font-weight: 500;
  font-size: 18px;
}

.software-innovation-hero-image {
  margin-right: -20px;
  margin-left: -230px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .software-innovation-hero-image {
    margin-left: -146px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .software-innovation-hero-image {
    margin-left: -146px;
  }
}

@media only screen and (max-width: 767px) {
  .software-innovation-hero-image {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 30px;
  }
}

.software-innovation-about-bg {
  background-image: url("../images/bg/soft-s2-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.software-innovation-video-box .video-link .ht-popup-video .video-button__two .video-play-icon::before {
  border-left-color: #fff;
}

.software-innovation-video-box .video-link .ht-popup-video .video-button__two .video-play {
  background: #086AD8;
}

.software-innovation-video-box .video-text {
  color: #086AD8;
}

.software-innovation-video-box .video-button__two {
  padding: 13px;
  border: 3px solid rgba(8, 106, 216, 0.4);
  border-radius: 50%;
}

.technology-service-bg {
  background-color: #002fa6;
  background-image: url("../images/bg/soft-s5-bg.png");
  background-repeat: no-repeat;
  background-position: left center;
}

.technology-service-area .text-infos {
  max-width: 470px;
  font-size: 18px;
}

.technology-service-area .ht-btn--outline {
  border-color: #fff;
  color: #fff !important;
}

.technology-service-area .ht-btn--outline:hover {
  border-color: #d2a98e;
  background: #d2a98e;
  color: #fff !important;
}
